import React, { Component } from 'react';
import { Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Fetcher } from '../../Helpers/fetcher.js';
import { Notify } from '../../Helpers/notification.js';
import { isEmpty } from 'lodash';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import { createBrowserHistory } from 'history';
import { withRouter } from "react-router-dom";

const history = createBrowserHistory();

class Login extends Component {
	constructor(props) {
		super(props);
		this.origin = window.location.origin;
		this.state = {
			username: '',
			password: ''
		}
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	loginUser = (e) => {
		e.preventDefault();
		const username = this.state.username;
		const password = this.state.password;

		if (username === '' || password === '') {
			Notify.notify('Please enter username & password');
		} else {
			const data = {
				username: username,
				password: password
			}
			return Fetcher.post(this.props.app.apiPath + '/api/obd/login', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
				.then(res => {
					if (res.message === 'Bad credentials') {
						Notify.notify('Wrong username or password');
					} else if (!isEmpty(res.token) && !isEmpty(res.userid)) {
						
						Notify.notify('Login Successful');
						this.props.setUserToken(res.userid, res.token, res.role)

						if(res.role.includes('ROLE_SUSER')){
							setTimeout( () => this.props.history.push("/sub-user-report"), 1500 );
						} 

					} else {
						console.log('Login failed:', res);
						Notify.notify('Login Failed');
					}
				})
				.catch(err => {
					console.log('Error in Login', err);
					Notify.notify('Login Failed');
				});

		}
	}

	setMessageShow = (status) => {
		this.setState({
		});
	}


	render() {
		return (
			<div className="app flex-row align-items-center login_page">
				<Container>
					<Row className="justify-content-center">
						<Col md="4">
							<Card className="p-4">
								<Card.Body>
									<Form method="post" onSubmit={this.loginUser}>
										<div className="login-logo text-center">
											{this.props.app.logo && <img className="navbar-brand-full" src={this.props.app.logo} alt="CoreUI Logo" />}
										</div>

										<p className="text-muted text-center">Sign in to your account</p>
										<InputGroup className="mb-3">
											<div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-user"></i></span></div>
											<Form.Control className="field-username" name="username" type="text" placeholder="Username" onChange={e => this.setvalue(e)} value={this.state.username} autoComplete="username" />
										</InputGroup>
										<InputGroup className="mb-4">
											<div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-lock"></i></span></div>
											<Form.Control name="password" type="password" placeholder="Password" onChange={e => this.setvalue(e)} value={this.state.password} autoComplete="current-password" />
										</InputGroup>
										<Row>
											<Col xs="3">
												<Button type="submit" color="primary" className="px-4 btn btn-primary btn-round">Login</Button>
											</Col>
										</Row>
									</Form>
									{
										this.origin === this.props.app.reportPath ? 
										<div className="position-absolute end-0 translate-middle">
											<a href={`/reset-pwd`}>Reset password?</a>
										</div>
										:
										<></>
									}
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default withRouter(Login);