import React, { Component } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { Redirect } from "react-router-dom";


// import "./index.css";
class UpdateCLI extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cli: '',
			cliid: '',
			parent: '',
			redirectToCliManagement: false,
		};

	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const cliId = this.props.selectedCliId;
		Fetcher.get(apiPath + '/api/obd/cli/detail/' + cliId)
			.then(res => {
				this.setState({
					cli: res.cli,
					cliId: res.cliId
				})
			})
			.catch(err => { console.log('Error in fetching CLI data', err) });
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	hideModal = () => {
		this.setState({
			showModal: false,
			redirectToCliManagement: this.state.redirectToCliManagement
		});
	}


	UpdateCLI = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);

		const data = {
			cliId: this.props.selectedCliId,
			cli: state.cli
		}
		this.saveData(data);

	}
	saveData = (data) => {
		return Fetcher.post(this.props.app.apiPath + '/api/obd/cli/update', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let modalHeading;
				let redirectToCliManagement;
				if (res.message === 'Parameters Incorrect' || res.message === 'Request Failed!! Please try again') {
					redirectToCliManagement = false;
				} else {
					redirectToCliManagement = true;
					Notify.notify(res.message);

				}
				this.setState({
					redirectToCliManagement: redirectToCliManagement
				})

			})
			.catch(err => {
				console.log('Error in Updating CLI', err)
				Notify.notify('Error in Updating CLI');
			});

	}

	render() {
		if (this.state.redirectToCliManagement === true) {
			return <Redirect to={'/cli-list/'} />
		}

		return (

			<Form method="post" onSubmit={this.UpdateCLI}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Update CLI</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group"  controlId="referrerDomain">
											<Form.Control required name="cli" type="text" placeholder="CLI" onChange={e => this.setvalue(e)} value={this.state.cli} />
										</Form.Group>
									</Col>
								</Row>
								
								<Button className="btn-round" variant="primary" type="submit">Update CLI</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default UpdateCLI;