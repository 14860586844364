import React, { Component } from 'react';
import { Button, Modal, Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import Dropdown from 'react-bootstrap/Dropdown';
import ToggleButton from 'react-toggle-button'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Cookies from 'js-cookie';

import "./index.css";
class ApiKeyList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			confirmDelete: false,
			selectedBlId: 0,
			userApiList : [],
			selecteApiKeyId : 0,
			sizePerPage: 10
		}
	}
	componentDidMount() {
		this.getUserApilist();
		const savedSizePerPage = Cookies.get('apiKeyListsizePerPage');
		if (savedSizePerPage) {
			this.setState({ sizePerPage: Number(savedSizePerPage) });
		}
	}

	getUserApilist = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/userApikey/'+userId)
			.then(res => {
				this.setState({
					userApiList: res
				})
			})
			.catch(err => { console.log('Error in fetching Api List', err) });
	}

	confirmDelete = (status, blId) => {
		this.setState({
			confirmDelete: status,
			selecteApiKeyId: blId
		});
	}

	deleteApiKey = () => {
		const wId = this.state.selecteApiKeyId;
		const data = {

		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/apikey/' + wId, { headers: { "Content-type": "application/json" }, method: 'DELETE', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.state.confirmDelete = false;
				this.getUserApilist();
			})
			.catch(err => {
				console.log('Error in Deleting Api Key', err);
			});
	}

	handleSizePerPageChange = (newSizePerPage) => {
		this.setState({ sizePerPage: newSizePerPage });
		Cookies.set('apiKeyListsizePerPage', newSizePerPage);
	}

	render() {

		const userApiList = this.state.userApiList;
		let columns = '';
		
			columns = [
				{
				dataField: 'id',
				text: 'Id',
				sort: false
			},
			{
				dataField: 'apikey',
				text: 'Api Key',
				sort: false,
				searchable: true
			},
			{
				dataField: 'ip',
				text: 'IP',
				sort: true,
				searchable: true
			},, {
				dataField: 'action',
				text: 'Action',
				sort: false,
				searchable: false
			},];

		const userapi = Array.isArray(userApiList) ? userApiList.map((blist, index) => {

			let action = [];
				action.push(<Dropdown>
					<Dropdown.Toggle className='btn-sm'>Take Action</Dropdown.Toggle>
						<Dropdown.Menu>
						<Dropdown.Item key={`${index}-edit-api-key`} href={`${this.props.app.urlPrefix}/update-api-key/${blist.id}`}>Edit Api Key</Dropdown.Item>	
					  	<Dropdown.Item key={`${index}-delete-api-key`} onClick={(e) => { this.confirmDelete(true, blist.id) }} href="#">Delete Api Key</Dropdown.Item>
						</Dropdown.Menu>
				  	</Dropdown>);
			
			return {
				id : blist.id,
				apikey: blist.apikey,
				ip: blist.ip,
				action: action
			};
		}) : []
		const { SearchBar, ClearSearchButton } = Search;

		const defaultSorted = [{
			dataField: 'id',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: userapi.length,
			sizePerPage: this.state.sizePerPage,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: this.handleSizePerPageChange
		};

		return (
			<Card>
				<Card.Header>User Api key List</Card.Header>
				<Card.Body>
					<div className="campaign-list-table-cont">
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (<ToolkitProvider keyField="webhookId" data={userapi} columns={columns} search bootstrap4>
									{props => (<div className="">
										Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
										<span className="search-cont"><SearchBar {...props.searchProps} /></span>
										<ClearSearchButton className="btn-primary" {...props.searchProps} />
										<hr />
										<BootstrapTable bordered={true} hover condensed defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="blacklist-table" {...props.baseProps} {...paginationTableProps} noDataIndication={() => (<div>{'No data available'}</div>)} />
										<PaginationListStandalone {...paginationProps} />
									</div>)}
								</ToolkitProvider>)
							}
						</PaginationProvider>}
					</div>
					
				<Modal size="md" show={this.state.confirmDelete} onHide={() => this.confirmDelete(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Delete Api Key
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to delete this Api Key?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmDelete(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.deleteApiKey} variant="primary">YES</Button>
						</Modal.Footer>
				</Modal>
				</Card.Body>
			</Card>
		);
	}
}


export default ApiKeyList;