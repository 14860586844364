import React, { Component } from 'react';
import { Card, Button, Form, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Notify } from "../../../Helpers/notification.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from 'js-cookie';

class SearchBlacklistDND extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cdrList: [],
            mobileNumber: '',
            apiPath: props.app.apiPath,
            role: props.app.role,
            userId: props.app.userId,
            noDataDisplayMsg: '',
            startDate: '',
            endDate: '',
            sizePerPage: 10
        }
        document.title = 'Search Blacklist or DND Number'
    }

    componentDidMount() {
		const savedSizePerPage = Cookies.get('callDetailRecordListsizePerPage');
		if (savedSizePerPage) {
			this.setState({ sizePerPage: Number(savedSizePerPage) });
		}
	}

    setvalue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    searchCdr = async (e) => {
        e.preventDefault();
       
        const data = {
            mobile : this.state.mobileNumber,
			userId : this.state.userId
        }

        return Fetcher.post(`${this.state.apiPath}/api/obd/search/Blacklist/DND`, { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
            .then(res => {
                this.setState({'cdrList': [res]});
                if(res.message === "Number not found in Blacklist or DND") this.setState({noDataDisplayMsg: 'No records found'});
            })
            .catch(err => {
                console.log('Error in fetching call detail records: ', err);
                Notify.notify('Error in fetching call detail records');
            });
    }

    handleStartDateChange = (date) => {
		this.setState({
			startDate: date,
	    });
    };

    handleEndDateChange = (date) => {
    		this.setState({
    			endDate: date,
    	 });
    };

    formatDate = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
	
		return `${year}-${month}-${day}`;
	};

    handleSizePerPageChange = (newSizePerPage) => {
		this.setState({ sizePerPage: newSizePerPage });
		Cookies.set('callDetailRecordListsizePerPage', newSizePerPage);
	}


    handleRemoveBlacklist = (mobileNum,operation) => {
        
		if (!mobileNum) {
			Notify.notify('Please enter a mobile number.');
			return;
		}

		
		console.log("Mobile Number : "+mobileNum);

		let data = {};
		let urlPrefix = "";
        
		data = {
			    "userId": this.state.userId,
			    "mobile": mobileNum,
                "operation":operation
	         };


		return Fetcher.post(`${this.state.apiPath}/api/obd/superadmin/delete/Blacklist/DND`, { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				
				
				let dndAdded = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
                    setTimeout(() => {
		        	window.location.reload(false);
		            }, 500);
				}
				
			})
			.catch(err => {
				console.log('Error in Removing Blacklisting Number', err);
				Notify.notify('Error in Removing Blacklisting Number ');
			});
	}


    handleRemoveDND = (mobileNum,operation) => {
        
		if (!mobileNum) {
			Notify.notify('Please enter a mobile number.');
			return;
		}

		
		console.log("Mobile Number : "+mobileNum);

		let data = {};
		let urlPrefix = "";
        
		data = {
			    "userId": this.state.userId,
			    "mobile": mobileNum,
                "operation":operation
	         };


		return Fetcher.post(`${this.state.apiPath}/api/obd/superadmin/delete/Blacklist/DND`, { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				
				
				let dndAdded = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
                    // window.location.reload();
                    setTimeout(() => {
		        	window.location.reload(false);
		            }, 500);
				}
				
			})
			.catch(err => {
				console.log('Error in Removing DND Number', err);
				Notify.notify('Error in Removing DND Number ');
			});
	}

    statusButton = (mobileNumber,statusB) => {

            if(statusB === "BLACKLIST"){
                return (<>
                       <Button variant="success" type="button" onClick={(e) => this.handleRemoveBlacklist(mobileNumber,"")}>Remove from Blacklist</Button>
                   </>);
            }
            else if(statusB === "DND"){
                return (<>
                       <Button variant="success" type="button" onClick={(e) => this.handleRemoveDND(mobileNumber,"")}>Remove from DND</Button>
                   </>);
            }
            else if(statusB === "BLACKLIST-DND"){
               return (<>
                       <Row>
                           <Col>
                            <Button variant="primary" className="mr-2" type="button" onClick={(e) => this.handleRemoveBlacklist(mobileNumber,"Blacklist")}>Remove from Blacklist</Button>
                            <Button variant="success" className="mr-2" type="button" onClick={(e) => this.handleRemoveDND(mobileNumber,"DND")}>Remove from DND</Button>
                           </Col>
                       </Row>
                   </>);
            }
            return "";
    }

    render() {

        const cdrList = this.state.cdrList;
        const columns = [
            {
				dataField: 'mobile',
				text: 'Mobile Number',
				sort: false,
				hidden: false
			},
            {
                dataField: 'status',
                text: 'Status',
                sort: false,
                hidden: false
            },
           {
				dataField: 'action',
				text: 'Action',
				sort: false,
				searchable: false
			}];

        const records = Array.isArray(cdrList) ? cdrList.map((row, index) => {
            return {
                mobile: row.mobile,
                status: row.status,
                action: this.statusButton(row.mobile,row.status)
            };
        }) : []


        const { SearchBar, ClearSearchButton } = Search;

        const defaultSorted = [{
            dataField: 'reqDate',
            order: 'desc'
        }];

        const paginationOption = {
            custom: true,
            totalSize: records.length,
            sizePerPage: this.state.sizePerPage,
            sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: this.handleSizePerPageChange
        };

        return (
            <Card>
                <Card.Header>Search BLACKLIST OR DND NUMBER</Card.Header>
                <Card.Body>
                    <div className="widget-list-table-cont">

                        <Form className="add-campaign-form" method='post' onSubmit={(e) => this.searchCdr(e)}>
                            <Row>  
                                <Col sm={6}>
                                    <Form.Group className="form-group col-md-6 float-left">
                                        <Form.Control required name="mobileNumber" type="number" placeholder="Mobile Number" onChange={e => this.setvalue(e)} value={this.state.mobileNumber} />
                                    </Form.Group>
                                </Col>
                                
                                <Col sm={4}>
                                    <Button variant="primary" type="submit">Search</Button>
                                </Col>
                            </Row>
                        </Form>
                        <br/>

                        <PaginationProvider pagination={paginationFactory(paginationOption)}>
                            {({
                                paginationProps, paginationTableProps
                            }) => (
                                <ToolkitProvider
                                    keyField="reqDate"
                                    data={records}
                                    columns={columns}
                                    search
                                    bootstrap4
                                >
                                    {
                                        props => (
                                            <div className="">
                                               
                                                <BootstrapTable defaultSorted={defaultSorted} bordered={true} striped={true} condensed wrapperClasses="table-responsive" classes="widgetlist-table" {...props.baseProps} {...paginationTableProps} noDataIndication={this.state.noDataDisplayMsg} />
                                                <PaginationListStandalone {...paginationProps} />
                                            </div>
                                        )}
                                </ToolkitProvider>
                            )}
                        </PaginationProvider>
                    </div>

                </Card.Body>
            </Card>

        );
    }
}

export default SearchBlacklistDND;