import React from 'react';
import AddVoiceCampaign from './AddVoiceCampaign';
import CloneVoiceCampaign from './CloneVoiceCampaign';
import VoiceCampaignInfo from './VoiceCampaignInfo';
import ListVoiceCampaign from './ListVoiceCampaign';
import ListHistoricalVoiceCampaign from './ListHistoricalVoiceCampaign';
import ListScheduleVoiceCampaign from './ListScheduleVoiceCampaign';
import AddVoiceFile from './AddVoiceFile';
import ListVoiceFiles from './ListVoiceFiles';
import AddVoicePlan from './AddVoicePlan';
import ListVoicePlans from './ListVoicePlans';
import AddVoiceBlacklist from './AddVoiceBlacklist';
import ListVoiceBlacklist from './ListVoiceBlacklist';
import UploadBlacklistFile from './UploadBlacklistFile';
import AddVoiceDnd from './AddVoiceDnd';
import UploadDndFile from './UploadDndFile';
import { Route, Switch } from "react-router-dom";
import ListSuperadminVoiceCampaign from './ListSuperadminVoiceCampaign';
import ManageCampaignSuperadmin from './ManageCampaignSuperadmin';
const Campaign = (props) => {
	const urlPrefix = props.app.urlPrefix;
	return (

		<div className="">
			<Switch>
				<Route path={`${urlPrefix}/add-voice-campaign`} ><AddVoiceCampaign app={props.app} /></Route>
				<Route path={`${urlPrefix}/voice-campaign-summary`} ><ListVoiceCampaign app={props.app} /></Route>
				<Route path={`${urlPrefix}/voice-superadmin-campaign-summary`} ><ListSuperadminVoiceCampaign app={props.app} /></Route>
				<Route path={`${urlPrefix}/historical-voice-campaign-summary`} ><ListHistoricalVoiceCampaign app={props.app} /></Route>
				<Route path={`${urlPrefix}/schedule-voice-campaign-summary`} ><ListScheduleVoiceCampaign app={props.app} /></Route>
				<Route path={`${urlPrefix}/add-voice-files`} ><AddVoiceFile app={props.app} /></Route>
				<Route path={`${urlPrefix}/voice-files`} ><ListVoiceFiles app={props.app} /></Route>
				<Route path={`${urlPrefix}/add-voice-plan`} ><AddVoicePlan app={props.app} /></Route>
				<Route path={`${urlPrefix}/voice-plans`} ><ListVoicePlans app={props.app} /></Route>
				<Route path={`${urlPrefix}/add-voice-blacklist`} ><AddVoiceBlacklist app={props.app} /></Route>
				<Route path={`${urlPrefix}/bulk-voice-blacklist`} ><UploadBlacklistFile app={props.app} /></Route>
				<Route path={`${urlPrefix}/add-blacklist-dnd`} ><AddVoiceDnd app={props.app} /></Route>
				<Route path={`${urlPrefix}/bulk-blacklist-dnd`} ><UploadDndFile app={props.app} /></Route>
				<Route path={`${urlPrefix}/voice-blacklist`} ><ListVoiceBlacklist app={props.app} /></Route>
				<Route path={`${urlPrefix}/voice-campaign-info/:campaignId`} render={(routeprops) => (
					<VoiceCampaignInfo campaignId={routeprops.match.params.campaignId} app={props.app} {...routeprops} />)
					} />
				<Route path={`${urlPrefix}/clone-campaign/:campaignId`} render={(routeprops) => (
					<CloneVoiceCampaign campaignId={routeprops.match.params.campaignId} app={props.app} {...routeprops} />)
					} />
				<Route path={`${urlPrefix}/manage-campaigns-superadmin`} ><ManageCampaignSuperadmin app={props.app} /></Route>	
			</Switch>
		</div>

	);
}

export default Campaign;
