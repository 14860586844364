import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class UploadApiCLIFile extends Component {
	constructor(props) {
		super(props);
		this.cliFileInput = React.createRef();
		let sitePath;
		let filePath;

		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);

		this.state = {
			cliFileType: 'newcliFile',
			cliFileInput: this.cliFileInput,
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			cliUploaded: false,
			pool: 'api',
			pilotNumber: '',
			pilotList: [],
			dialerList: [],
			dialerId: ''
		}

	}

	componentDidMount() {
		Fetcher.get(this.state.apiPath + '/api/obd/pilot/list')
			.then(res => {
				this.setState({
					pilotList: res
				})
			})
			.catch(err => { console.log('Error in fetching Pilot List', err) });
		Fetcher.get(this.state.apiPath + '/api/obd/dialers/name/list')
		.then(res => {
			this.setState({
				dialerList: res
			})
		})
		.catch(err => { console.log('Error in fetching Pilot List', err) });

	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setUploadType = (type, uploadTypeKey) => {
		this.setState({
			[uploadTypeKey]: type,
		});
	}

	setMessageShow = (status) => {
		this.setState({
			
		});
	}

	onFileChangeHandler = event => {
		//if ( this.checkMimeType( event ) && this.checkFileSize( event ) ) {
		if (this.checkMimeType(event)) {

		}
	}
	// Add Voice File

	handleAddCliFileSubmit = (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		console.log(formData);

		const cliFile = formData.cliFileInput.current.files[0];
		const fd = new FormData();
		fd.append('cliFile', cliFile);
		fd.append('dialerId', formData.dialerId);
		fd.append('userId', this.state.userId);
		fd.append('pool', formData.pool);
		fd.append('pilotNumber', formData.pilotNumber);

		return Fetcher.post(formData.apiPath + '/api/obd/api/cliupload', { method: 'POST', body: fd })
			.then(res => {
				let modalHeading;
				let cliUploaded = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Parameters Incorrect');
				} else {
					Notify.notify(res.message);
					cliUploaded = true;
				}
				this.setState({
					cliUploaded: cliUploaded
				})
			})
			.catch(err => {
				console.log('Error in uploading CLI File to Server', err);
				Notify.notify('Error in uploading CLI File to Server');
			});
	}

	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	getFileNameFromUrl = (url) => {
		var n = url.lastIndexOf('/');
		return url.substring(n + 1);
	}

	checkMimeType = (event) => {
		let files = event.target.files
		let allowedTypes = ['text/plain', 'text/csv'];
		if (event.target.name === 'newContactsFile') {
			allowedTypes = ['text/plain', 'text/csv'];
		}
		let err = [] // create empty array

		for (var x = 0; x < files.length; x++) {
			if (allowedTypes.every(type => files[x].type !== type)) {
				err[x] = files[x].type + ' is not a supported format\n';
				// assign message to array
			}
		};
		for (var z = 0; z < err.length; z++) { // loop create toast massage
			event.target.value = null;
		}
		return true;
	}

	checkFileSize = (event) => {
		let allowedSize = 1;
		if (event.target.name === 'newContactsFile') {
			allowedSize = 20;
		}

		let files = event.target.files
		let err = [];
		for (var x = 0; x < files.length; x++) {
			if (((files[x].size / 1024 / 1024).toFixed(2)) > allowedSize) {
				err[x] = files[x].size + ' is too large, please pick a smaller file\n';
			}
		};
		for (var z = 0; z < err.length; z++) {
			event.target.value = null
		}
		return true;
	}



	render() {

		let submitButtonlabel = 'Upload File';
		if (this.state.cliUploaded === true) {
			return <Redirect to={'/cli-api-list/'} />
		}
		const pilotList = cloneDeep(this.state.pilotList);
		const dialerList = cloneDeep(this.state.dialerList);
		let pilotDropdown = '';
		if (!isEmpty(pilotList) && (!('error' in pilotList))) {
			pilotDropdown = pilotList.map((obj, index) => {
				return <option value={obj.pilotNumber} key={`pilotid${index}`} >{obj.pilotNumber}</option>
			})

		} else {
			pilotDropdown = <option value="" >No Pilot No. found</option>
		}

		let dialerDropdown = '';
		if (!isEmpty(dialerList) && (!('error' in dialerList))) {
			dialerDropdown = dialerList.map((obj, index) => {
				return <option value={obj.dialerId} key={`dialerid${index}`} >{obj.dialerName}</option>
			})

		} else {
			dialerDropdown = <option value="" >No Dialer found</option>
		}

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddCliFileSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Upload CLI File</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={12}>
										<Form.Label>Upload CLI File(.txt OR .csv format only)</Form.Label>
										<Form.Group controlId="cli-file-upload">
											<Form.Control required name="newcliFile" onChange={this.onFileChangeHandler} ref={this.cliFileInput} type="file" />
										</Form.Group>
									</Col>

									<Col sm={6}>
										<Form.Group className="form-group" >
											<Form.Label>Select Dialer</Form.Label>
											<Form.Select required name="dialerId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Dialer</option>
												{dialerDropdown}
											</Form.Select>
										</Form.Group>
									</Col>

									<Col sm={6}>
										<Form.Group>
											<Form.Label>Select Pilot No.</Form.Label>
											<Form.Select required name="pilotNumber" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Pilot No.</option>
												{pilotDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}
}

export default UploadApiCLIFile;