import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class CreateTrunk extends Component {
	constructor(props) {
		super(props);

		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			trunkCreated: false,
			trunkName: '',
			pilotNumber: '',
			channels: '',
			cps: ''
		}

	}

	componentDidMount() {
		
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		const trunkCreated = this.state.trunkCreated;
		this.setState({
			redirectToSummary: trunkCreated
		});
	}

	// Add CLI
	handleCreateTrunkSubmit = async (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		console.log(formData);
		const data = {
			"trunkName": formData.trunkName,
			"pilotNumber": formData.pilotNumber,
			"channels": this.state.channels,
			"cps": this.state.cps
		}
		this.addNewTrunk(data);
	}

	addNewTrunk = (data) => {
		console.log(data);
		return Fetcher.post(this.state.apiPath + '/api/obd/trunk', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let trunkCreated = false;
				if (res.message === 'Parameters Incorrect' || 'Trunk Name already exists') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
					trunkCreated = true;
				}
				this.setState({
					trunkCreated: trunkCreated
				})

			})
			.catch(err => {
				console.log('Error in Creating Trunk', err);
				Notify.notify('Error in Creating Trunk');
				this.setState({
					trunkCreated: false
				})
			});
	}

	render() {

		if (this.state.trunkCreated === true) {
			return <Redirect to={'/trunk-list/'} />
		}

		let submitButtonlabel = 'Create Trunk';

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleCreateTrunkSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Create Trunk</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={3}>
										<Form.Group className="form-group" >
											<Form.Control required name="trunkName" type="text" placeholder="Trunk Name" onChange={e => this.setvalue(e)} value={this.state.trunkName} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group" >
											<Form.Control required name="pilotNumber" type="text" placeholder="Pilot Number" onChange={e => this.setvalue(e)} value={this.state.pilotNumber} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group" >
											<Form.Control required name="channels" type="text" placeholder="Channels" onChange={e => this.setvalue(e)} value={this.state.channels} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group" >
											<Form.Control required name="cps" type="text" placeholder="CPS" onChange={e => this.setvalue(e)} value={this.state.cps} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default CreateTrunk;