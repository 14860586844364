import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Modal, Card, Col, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import 'bootstrap/dist/css/bootstrap.css';
import Dropdown from 'react-bootstrap/Dropdown';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { cloneDeep, isEmpty } from 'lodash';
import Cookies from 'js-cookie';

import "./index.css";
class SubUserManagement extends Component {
	constructor(props) {

		super(props);
		this.state = {
			userList: [],
			adminCredits: 0,
			creditsToAdd: 0,
			creditsToRemove: 0,
			confirmDelete: false,
			confirmUpdateAccountType: false,
			selectedUserId: 0,
			showAddCreditModal: false,
			showRemoveCreditModal: false,
			password: '',
			confirmPassword: '',
			showPwdModal: false,
			confirmRemoveSettingStatus: false,
			selectedSubId: 0,
			selectedSettingUserId  : 0,
			selectedAllocateApiCliUserId : '',
			selectedAllocateDedicatedCliUserId : '',
			confirmAllocateApiCliUserId : false,
			confirmAllocateDedicatedCliUserId : false,
			manualAllocation : false,
			manualDedicatedAllocation : false,
			bulkAllocation : false,
			bulkDedicatedAllocation: false,
			cliList : [],
			dedicatedCliList: [],
			allocateApiCliId : '',
			allocateDedicatedCliId: '',
			cliRange : '',
			dedicatedCliRange: '',
			cliId : '',
			dedicatedCliId: '',
			allocatedCliList: [],
			allocatedDedicatedCliList: [],
			allocatedCliListFlag: false,
			allocatedDedicatedCliListFlag: false,
			allocatedClisIds : '',
			allocatedDedicatedClisIds : '',
			sizePerPage: 10,
			apiCliFlag: false,
			dedicatedCliFlag: false,
			activeUserId: null,
			loading: false,
			locationList: [],
			selectedLocations: [],
			activeLocationId: null,
			activeGroupId: null,
			activeModuleId: null,
			selectedUserAdditionalInfo: {},
			ttsFlag : false,
			moduleList : [],
			showAddTTSCreditModal: false,
			showRemoveTTSCreditModal : false,
			showSubmission: true
		}
	}
	componentDidMount() {
		this.getUsers();
		
		const savedSizePerPage = Cookies.get('userListsizePerPage');
		if (savedSizePerPage) {
			this.setState({ sizePerPage: Number(savedSizePerPage) });
		}
	}


	getUsers = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/user/' + userId)
			.then(res => {
				this.setState({
					userList: res.users,
					adminCredits: res.adminCredits,
					showAddCreditModal: false,
					showRemoveCreditModal: false,
					showPwdModal: false,
					confirmDelete: false,
					confirmUpdateAccountType: false,
					showAddTTSCreditModal: false,
					showRemoveTTSCreditModal: false
				})
			})
			.catch(err => {
				console.log('Error in fetching Users List', err);
			});
	}

	confirmDelete = (status, userId) => {
		this.setState({
			confirmDelete: status,
			selectedUserId: userId
		});
	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
		if(name === "manualAllocation"){
			this.state.bulkAllocation = false
		}
		if( name === "manualDedicatedAllocation"){
			this.state.bulkDedicatedAllocation = false
		}
		if(name === "bulkAllocation"){
			this.state.manualAllocation = false
		}
		if( name === "bulkDedicatedAllocation"){
			this.state.manualDedicatedAllocation = false
		}
	}

	confirmSettingRemove = (status, subId,userId) => {
		this.setState({
			confirmRemoveSettingStatus: status,
			selectedSubId: subId,
			selectedSettingUserId : userId
		});
	}

	deleteUser = () => {
		const userId = this.state.selectedUserId;
		const data = {
			parent: this.props.app.userId,
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/user/' + userId, { headers: { "Content-type": "application/json" }, method: 'DELETE', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getUsers();
			})
			.catch(err => {
				console.log('Error in Deleting User', err)
				Notify.notify('Error in Deleting User');
			});
	}
	showPassword = (userId) => {
		const apiPath = this.props.app.apiPath;
		Fetcher.get(apiPath + '/api/obd/pwd/' + userId)
			.then(res => {
				alert(res.password);
			})
			.catch(err => {
				console.log('Error in fetching Password', err);
				Notify.notify('Error in fetching Password');
			});
		
	}
	addCredits = (event) => {
		event.preventDefault();
		if (this.state.creditsToAdd <= 0) {
			Notify.notify('Recharge Amount cannot be less than or equal to 0');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			credits: this.state.creditsToAdd,
		}
		return Fetcher.post(this.props.app.apiPath + '/api/obd/credits/add', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Recharge Failed.');
				} else {
					Notify.notify(res.message);
				}
				this.setState({
					creditsToAdd: 0
				})
				this.getUsers();
				//window.location.reload(true);
			})
			.catch(err => {
				console.log('Error in Recharge', err)
				Notify.notify('Error in Recharge');
			});
	}


	addTTSCredits = (event) => {
		event.preventDefault();
		if (this.state.creditsToAdd <= 0) {
			Notify.notify('Credits cannot be less than or equal to 0');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			credits: this.state.creditsToAdd,
		}
		// this.props.app.apiPath
		return Fetcher.post(this.props.app.apiPath + '/api/obd/tts/credits/add', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Recharge Failed.');
				} else {
					Notify.notify(res.message);
				}
				this.setState({
					creditsToAdd: 0
				})
				window.location.reload(true);
			})
			.catch(err => {
				console.log('Error in Recharge', err)
				Notify.notify('Error in Recharge');
			});
	}

	removeCredits = (event) => {
		event.preventDefault();
		if (this.state.creditsToRemove <= 0) {
			Notify.notify('Recharge amount cannot be less than or equal to 0');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			credits: this.state.creditsToRemove,
		}
		return Fetcher.post(this.props.app.apiPath + '/api/obd/credits/remove', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Recharge Failed');
				} else {
					Notify.notify(res.message);
				}
				this.setState({
					creditsToRemove: 0
				})
				this.getUsers();
				//window.location.reload(true);
			})
			.catch(err => {
				console.log('Error in Recharge', err)
				Notify.notify('Error in Recharge');
			});
	}

	removeTTSCredits = (event) => {
		event.preventDefault();
		if (this.state.creditsToRemove <= 0) {
			Notify.notify('Credits cannot be less than or equal to 0');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			credits: this.state.creditsToRemove,
		}
		// this.props.app.apiPath
		return Fetcher.post(this.props.app.apiPath + '/api/obd/tts/credits/remove', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Recharge Failed');
				} else {
					Notify.notify(res.message);
				}
				this.setState({
					creditsToRemove: 0
				})
				window.location.reload(true);
			})
			.catch(err => {
				console.log('Error in Recharge', err)
				Notify.notify('Error in Recharge');
			});
	}

	resetPassword = (event) => {
		event.preventDefault();
		if (this.state.confirmPassword !== this.state.password) {
			Notify.notify('Password & Confim Password don\'t match');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			password: this.state.password,
		}
		return Fetcher.post(this.props.app.apiPath + '/api/obd/pwd/reset', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Reset Password Failed');
				} else {
					Notify.notify(res.message);
				}
				this.getUsers();
			})
			.catch(err => {
				console.log('Error in Reset Password', err)
				Notify.notify('Error in Reset Password');
			});
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
			showAddCreditModal: false,
			showRemoveCreditModal: false,
			showPwdModal: false,
			showAddTTSCreditModal: false,
			showRemoveTTSCreditModal: false,
		});
	}

	showAddCreditModal = (userId) => {
		this.setState({
			showAddCreditModal: true,
			selectedUserId: userId
		});
	}
	showRemoveCreditModal = (userId) => {
		this.setState({
			showRemoveCreditModal: true,
			selectedUserId: userId
		});
	}

	showPwdModal = (userId) => {
		this.setState({
			showPwdModal: true,
			selectedUserId: userId
		});
	}

	handleSelect = function (selectedItems) {
		const cliSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			cliSelected.push(selectedItems[i].value);
		}
		this.setState({
			allocateApiCliId: cliSelected.toString()
		});
		console.log("Cli Ids : "+this.state.allocateApiCliId);
	}


	handleSizePerPageChange = (newSizePerPage) => {
		this.setState({ sizePerPage: newSizePerPage });
		Cookies.set('userListsizePerPage', newSizePerPage);
	}


	handleDropdownToggle = (isOpen) => {
		if (!isOpen) {
			this.setState({ activeUserId: null });
		}
	};

	
	getUserAdditionalInfo = (userId, type) => {
		if(this.state.loading === true){
			return;
		}
		this.setState({
			...(type === 'location' && { activeLocationId: userId }),
			...(type === 'group' && { activeGroupId: userId }),
			...(type === 'module' && { activeModuleId: userId }),
			loading: true
		})
		const apiPath = this.props.app.apiPath;
		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
					selectedUserAdditionalInfo: res,
					loading: false
				})
			})
			.catch(err => { 
				console.log('Error in fetching Location list', err) 
				this.setState({
					loading: false
				})
			});
	}

	handleDropdownToggleAction = (isOpen, type) => {
		if (!isOpen) {
			if (type === 'location') {
				this.setState({ activeLocationId: null });
			} else if (type === 'group') {
				this.setState({ activeGroupId: null });
			} else if (type === 'module') {
				this.setState({ activeModuleId: null });
			}
		}
	};

	confirmUpdateAccountType = (status, userId, accountType) => {
		this.setState({
			confirmUpdateAccountType: status,
			selectedUserId: userId,
			selectedUserccountType: accountType,
		});
	}
	toggleAccountType = () => {
		const userId = this.state.selectedUserId;
		const accountType = this.state.selectedUserccountType;
		const data = {
			userId: userId,
			accountType: accountType === 0 ? 1 : 0
		}
		return Fetcher.post(this.props.app.apiPath + '/api/obd/change/accountType', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Error in updating account type');
				} else {
					Notify.notify(res.message);
				}
				this.getUsers();
			})
			.catch(err => {
				console.log('Error in updating account type', err)
				Notify.notify('Error in updating account type');
			})
			.finally(() => {
				this.setState({ confirmRemoveAllocatedCli: false })
			});
	}

	getProfile = (id) => {

		Fetcher.get(this.props.app.apiPath + '/api/obd/module/list/' + id)
			.then(res => {
				this.setState({
					moduleList: res
				})
				if (!isEmpty(this.state.moduleList) && (!('error' in this.state.moduleList))) {
					this.state.moduleList.map((fileObj) => {
						
						if(fileObj.moduleName === 'TTS'){
                            this.state.ttsFlag = true;
							console.log("TTS Flag :"+this.state.ttsFlag);
						}
					})
				}
			})
			.catch(err => {
				console.log('Error in fetching Module List', err);
			});
        return this.state.ttsFlag;
	}
	

	render() {

		const userList = this.state.userList;
		var columns;
		if (this.props.app.role === 'ROLE_USER') {
			columns = [
				{
					dataField: 'userId',
					text: 'User Id',
					sort: true,
					hidden: false
				},
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'accountType',
					text: 'Account Type',
					sort: true,
					searchable: false,
					headerStyle: { width: '130px' },
				},
				{
					dataField: 'company',
					text: 'Company',
					sort: true,
					searchable: false
				}, {
					dataField: 'userType',
					text: 'Type',
					sort: true,
					searchable: false
				},
                {
					dataField: 'parentUser',
					text: 'Parent',
					sort: false,
					searchable: false
				},{
					dataField: 'expiryDate',
					text: 'User Expiry',
					sort: false,
					searchable: false
				},{
					dataField: 'deleted',
					text: 'Status',
					sort: false,
					searchable: false
				},{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false,
					headerStyle: { width: '130px' }
				}];
		}
		
		const users = Array.isArray(userList) ? userList.map((user, index) => {
			if (this.props.app.role === 'ROLE_USER') {
				let action = [];
				action.push(<Dropdown key={`${index}-dropdown`}>
					<Dropdown.Toggle className="btn-sm" variant="primary">Take Action</Dropdown.Toggle>
						<Dropdown.Menu>
					  		<Dropdown.Item key={`${index}-edit-user`} href={`${this.props.app.urlPrefix}/update-sub-user/${user.userId}`}>Edit User</Dropdown.Item>
					  		{/* <Dropdown.Item key={`${index}-add-credit`} onClick={(e) => { this.showAddCreditModal(user.userId) }} href="#">Add Recharge</Dropdown.Item>
							<Dropdown.Item key={`${index}-remove-credit`} onClick={(e) => { this.showRemoveCreditModal(user.userId) }} href="#">Remove Recharge</Dropdown.Item> */}
					  		<Dropdown.Item key={`${index}-reset-pwd`} onClick={(e) => { this.showPwdModal(user.userId) }} href="#">Reset Password</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-delete-user`} onClick={(e) => { this.confirmDelete(true, user.userId) }} href="#">Block User</Dropdown.Item>
							<Dropdown.Item key={`${index}-pwd`} onClick={(e) => { this.showPassword(user.userId) }} href="#">Show Password</Dropdown.Item>
						    </Dropdown.Menu>
				  	</Dropdown>);
				
				return {
					userId: user.userId,
					username: user.username,
					accountType: (user.accountType== 0 ? "Promotional" : "Transactional"),
					company: user.company,
					userType: user.userType,
					planName: user.planName,
					parentUser: user.parentUser,
					expiryDate: user.expiryDate,
					deleted: user.deleted  === 0 ? "Active" : "In-Active",
					action: action
				};
			}
			
		}) : []
		const { SearchBar, ClearSearchButton } = Search;
		const { ExportCSVButton } = CSVExport;

		const defaultSorted = [{
			dataField: 'userId',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: users.length,
			sizePerPage: this.state.sizePerPage,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: this.handleSizePerPageChange
		};


		return (
			<Card>
				<Card.Header>Users List</Card.Header>
				{/*<Card.Header>Users List<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span></Card.Header>*/}

				<Card.Body>
					<div className="user-list-table-cont">
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="userId"
										data={users}
										columns={columns}
										search
										bootstrap4
										exportCSV={ {
											fileName: 'users.csv'
										  } }
									>
										{
											props => (
												<div>
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
													{/*<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span>*/}
													<Link className="float-right btn btn-default btn-primary" to={`${this.props.app.urlPrefix}/create-sub-user`}>Add User</Link>
													<hr/>
													<BootstrapTable defaultSorted={defaultSorted} hover bordered={true} wrapperClasses="table-responsive" classes="userlist-table"  {...props.baseProps}  {...paginationTableProps} />
													<PaginationListStandalone {...paginationProps} />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>
					<Modal size="md" show={this.state.showAddCreditModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Add Recharge Amount
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.addCredits}>
								<Row>
									<Col sm={12}>
										<Form.Group className="form-group" >
											<Form.Control required name="creditsToAdd" type="number" placeholder="Recharge Amount(Rs)" onChange={e => this.setvalue(e)} value={this.state.creditsToAdd} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Add Recharge</Button>
							</Form>
						</Modal.Body>
					</Modal>


					<Modal size="md" show={this.state.showRemoveCreditModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Remove Recharge Amount
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.removeCredits}>
								<Row>
									<Col sm={12}>
										<Form.Group className="form-group" >
											<Form.Control required name="creditsToRemove" type="number" placeholder="Recharge Amount(Rs)" onChange={e => this.setvalue(e)} value={this.state.creditsToRemove} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Remove Recharge</Button>
							</Form>
						</Modal.Body>
					</Modal>


					<Modal size="md" show={this.state.showRemoveTTSCreditModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Remove Recharge Credits
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.removeTTSCredits}>
								<Row>
									<Col sm={12}>
										<Form.Group className="form-group" >
											<Form.Control required name="creditsToRemove" type="number" placeholder="Recharge Amount(Rs)" onChange={e => this.setvalue(e)} value={this.state.creditsToRemove} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Remove Recharge</Button>
							</Form>
						</Modal.Body>
					</Modal>

					<Modal size="md" show={this.state.showPwdModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Reset Password
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.resetPassword}>
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group" >
											<Form.Control required name="password" type="password" placeholder="Password" onChange={e => this.setvalue(e)} value={this.state.password} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group" >
											<Form.Control required name="confirmPassword" type="password" placeholder="Confirm Password" onChange={e => this.setvalue(e)} value={this.state.confirmPassword} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Reset Password</Button>
							</Form>
						</Modal.Body>
					</Modal>

					<Modal size="md" show={this.state.confirmDelete} onHide={() => this.confirmDelete(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Block User
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to block this user?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmDelete(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.deleteUser} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>

				</Card.Body>
			</Card>

		);
	}
}

export default SubUserManagement;