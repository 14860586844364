import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal, Tabs, Tab } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class AddSMSCampaign extends Component {
	constructor(props) {
		super(props);
		this.baseFileInput = React.createRef();
		let sitePath;
		let filePath;
		if (props.app.mode === 'dev') {
			sitePath = 'http://core-php.local';
			filePath = 'http://core-php.local/';
		} else {
			const protocol = window.location.protocol;
			const slashes = protocol.concat("//");
			sitePath = slashes.concat(window.location.hostname);

			filePath = 'https://ucmapi.noblestack.co/assets/sms/';
		}
		this.state = {
			campaignName: '',
			templateId: '0',
			baseFileType: 'contactList',
			sendLater: false,
			sendDate: new Date(),
			baseFileInput: this.baseFileInput,
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			contactList: '',
			oldBaseId: '',
			senderId: '',
			baseUploads: [],
			senderIdList: [],
			showModal: false,
			modalHeading: 'Status',
			modalContent: '',
			redirectToSummary: false,
			campaignCreated: false,
			sendSms: false,
			smsText: '',

		}

	}

	componentDidMount() {
		const userId = this.state.userId;
		const apiPath = this.state.apiPath;

		// Get BaseUpload Files List
		Fetcher.get(apiPath + '/app/sms/base/' + userId)
			.then(res => {
				this.setState({
					baseUploads: res
				})
			})
			.catch(err => { console.log('Error in fetching BaseUpload Files', err) });

		// Get Sender Id List
		Fetcher.get(apiPath + '/app/sms/cli/' + userId)
			.then(res => {
				this.setState({
					senderIdList: res
				})
			})
			.catch(err => { console.log('Error in fetching Sender Id\'s', err) });
	}

	sendLaterDateChange = date => {
		this.setState({
			sendDate: date
		});
	};
	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	setCampaignType = (e) => {

		this.setState({
			sendSms: false,
			templateId: e.target.value,
			smsText: ''
		});
	}

	setUploadType = (type, uploadTypeKey) => {
		this.setState({
			[uploadTypeKey]: type,
		});
	}

	setMessageShow = (status) => {
		const campaignCreated = this.state.campaignCreated;
		this.setState({
			showModal: status,
			redirectToSummary: campaignCreated
		});
	}


	onFileChangeHandler = event => {
		//if ( this.checkMimeType( event ) && this.checkFileSize( event ) ) {
		if (this.checkMimeType(event)) {

		}
	}



	// Add Campaign

	handleAddCampaignSubmit = (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		let contactList = '';
		console.log(formData);
		const date = formData.sendDate;
		let sendDateTime = this.addZero(date.getFullYear());
		sendDateTime += "-";
		sendDateTime += this.addZero((date.getMonth() + 1));
		sendDateTime += "-"
		sendDateTime += this.addZero(date.getDate());
		sendDateTime += " "
		sendDateTime += this.addZero(date.getHours());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getMinutes());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getSeconds());
		if (formData.baseFileType === 'contactList') {
			contactList = formData.contactList;
		}
		this.uploadBaseFiles(formData)
			.then(([baseId]) => {
				const data = {
					"userId": formData.userId,
					"campaignName": formData.campaignName,
					"templateId": formData.templateId,
					"baseId": baseId,
					"scheduleTime": sendDateTime,
					"contactList": contactList,
					"cli": formData.senderId,
					"smsText": formData.smsText
				}
				this.addNewCampiagn(data);

			})

	}

	uploadBaseFiles = (formData) => {
		return Promise.all([this.maybeUploadBasefile(formData)])
	}

	maybeUploadBasefile = (formData) => {
		if (formData.baseFileType === 'oldBaseFile') {
			return formData.oldBaseId
		} else if (formData.baseFileType === 'newBaseFile') {
			const baseFile = formData.baseFileInput.current.files[0];
			const sitePath = this.state.sitePath;
			const filePath = this.state.filePath;

			const fd = new FormData();
			fd.append('smsBaseFile', baseFile);
			fd.append('fileType', 'baseFile');
			fd.append('userId', formData.userId);
			return Fetcher.post(sitePath + '/fileupload.php', { method: 'POST', body: fd })
				.then(res => {
					console.log(res)
					const basefileInfo = res;
					// Add New base Upload to db
					if (basefileInfo.uploaded) {
						let data = {
							"userId": formData.userId,
							"baseName": filePath + basefileInfo.file_name
						}

						return Fetcher.post(formData.apiPath + '/app/sms/baseupload', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
							.then(res => {
								return res.baseId;

							})
							.catch(err => { console.log('Error in uploading BaseUpload Files to Server', err) });
					} else {
						return '';
					}


				})
				.catch(err => { console.log('Error in uploading BaseUpload Files', err) });
		} else {
			return '';
		}
	}

	addNewCampiagn = (data) => {
		//	console.log( data );
		return Fetcher.post(this.state.apiPath + '/app/sms/campaign', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let modalHeading;
				let campaignCreated = false;
				if (res.message === 'Parameters Incorrect') {
					modalHeading = 'Error';
				} else {
					modalHeading = 'Success';
					campaignCreated = true;
				}
				this.setState({
					showModal: true,
					modalHeading: modalHeading,
					modalContent: res.message,
					campaignCreated: campaignCreated
				})

			})
			.catch(err => {
				console.log('Error in Add Campaign Files', err)
				this.setState({
					showModal: true,
					modalHeading: 'Error',
					modalContent: err.message
				})
			});
	}


	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	getFileNameFromUrl = (url) => {
		var n = url.lastIndexOf('/');
		return url.substring(n + 1);
	}

	checkMimeType = (event) => {
		let files = event.target.files
		let allowedTypes = ['text/plain', 'text/csv'];
		if (event.target.name === 'newContactsFile') {
			allowedTypes = ['text/plain', 'text/csv'];
		} else {
			allowedTypes = ['audio/wav'];
		}
		let err = [] // create empty array

		for (var x = 0; x < files.length; x++) {
			if (allowedTypes.every(type => files[x].type !== type)) {
				err[x] = files[x].type + ' is not a supported format\n';
				// assign message to array
			}
		};
		for (var z = 0; z < err.length; z++) { // loop create toast massage
			event.target.value = null
			this.setState({
				showModal: true,
				modalHeading: 'Error',
				modalContent: err[z]
			})
			//toast.error( err[ z ] )
		}
		return true;
	}

	checkFileSize = (event) => {
		let allowedSize = 1;
		if (event.target.name === 'newContactsFile') {
			allowedSize = 20;
		}

		let files = event.target.files
		let err = [];
		for (var x = 0; x < files.length; x++) {
			if (((files[x].size / 1024 / 1024).toFixed(2)) > allowedSize) {
				err[x] = files[x].size + ' is too large, please pick a smaller file\n';
			}
		};
		for (var z = 0; z < err.length; z++) {
			//console.log( err )
			this.setState({
				showModal: true,
				modalHeading: 'Error',
				modalContent: err[z]
			})
			event.target.value = null
		}
		return true;
	}



	render() {
		// Redirect to Summary if Campaign Added successfully.
		if (this.state.redirectToSummary === true) {
			return <Redirect to='/sms-campaign-summary' />
		}

		//console.log( 'Add Campaign State', this.state );
		const baseUploads = cloneDeep(this.state.baseUploads);
		const senderIdList = cloneDeep(this.state.senderIdList);
		const sendLater = this.state.sendLater;
		let sendLaterDatepicker = '';
		let submitButtonlabel = 'Send Now';

		if (sendLater) {
			sendLaterDatepicker = <Row>
				<Col sm={10}>
					<Form.Group className="form-group"  controlId="send-date">
						<Form.Label>Enter Date</Form.Label>
						<DatePicker
							className="form-control"
							showTimeSelect
							timeIntervals={15}
							selected={this.state.sendDate}
							timeCaption="Time"
							dateFormat="yyyy-MM-dd hh:mm:ss"
							onChange={this.sendLaterDateChange}
						/>
					</Form.Group>
				</Col>
			</Row>

			submitButtonlabel = 'Save';
		}

		let senderIdDropdown = '';
		if (!isEmpty(senderIdList) && (!('error' in senderIdList))) {
			senderIdDropdown = senderIdList.map((obj, index) => {
				return <option value={obj.cliName} key={`senderid${index}`} >{obj.cliName}</option>
			})

		} else {
			senderIdDropdown = <option value="" >No ID found</option>
		}


		let baseUploadDropdown = '';

		if (!isEmpty(baseUploads) && (!('error' in baseUploads))) {
			baseUploadDropdown = baseUploads.map((fileObj, index) => {
				return <option value={fileObj.baseId} key={`basefile${index}`} >{this.getFileNameFromUrl(fileObj.baseName)}</option>
			})

		} else {
			baseUploadDropdown = <option value="" >No Files</option>
		}

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddCampaignSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Compose SMS Campaign</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group"  controlId="campaign-name">
											{/* <Form.Label>Campaign Name</Form.Label> */}
											<Form.Control required name="campaignName" onChange={e => this.setvalue(e)} value={this.state.campaignName} type="text" placeholder="Campaign Name" />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group"  controlId="sender-id">
											<Form.Label>From : Sender ID</Form.Label>
											<Form.Control name="senderId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Sender Id</option>
												{senderIdDropdown}
											</Form.Control>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={8}>
										<Form.Label>Upload Contacts( Please prefix country code. )</Form.Label>
										<Tabs defaultActiveKey="contactList" className="" onSelect={k => this.setUploadType(k, 'baseFileType')}>
											<Tab eventKey="contactList" title="Contact Numbers">
												<Form.Group className="form-group"  >
													<Form.Control onChange={e => this.setvalue(e)} name="contactList" as="textarea" rows="3" />
												</Form.Group>
											</Tab>
											<Tab eventKey="newBaseFile" title="Upload New Contacts File">
												<Form.Group className="form-group"  >
													<Form.Control name="newContactsFile" onChange={this.onFileChangeHandler} ref={this.baseFileInput} type="file" />
												</Form.Group>
											</Tab>
											<Tab eventKey="oldBaseFile" title="Old Contacts File">
												<Form.Group className="form-group"  >
													{/* <Form.Label>Select File</Form.Label> */}
													<Form.Control as="select" name="oldBaseId" onChange={e => this.setvalue(e)} >
														<option value="">Select File</option>
														{baseUploadDropdown}
													</Form.Control>
												</Form.Group>
											</Tab>
										</Tabs>

									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group"  ></Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group"  >
											<Form.Label>Sms Text</Form.Label>
											<Form.Control value={this.state.smsText} onChange={e => this.setvalue(e)} name="smsText" as="textarea" rows="3" />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group"  controlId="Send-later">
											<Form.Check checked={this.state.sendlater} onChange={(e) => this.toggleChange(e, 'sendLater')} custom inline name="send-later" label="Send Later" id="send-later" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>
								{sendLaterDatepicker}
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
				<Modal size="sm" show={this.state.showModal} onHide={() => this.setMessageShow(false)}>
					<Modal.Header closeButton>
						<Modal.Title id="example-modal-sizes-title-sm">
							{this.state.modalHeading}
						</Modal.Title>

					</Modal.Header>
					<Modal.Body>{this.state.modalContent} </Modal.Body>
				</Modal>

			</Form>




		);
	}
}

export default AddSMSCampaign;