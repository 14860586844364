import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class CreateLocation extends Component {
	constructor(props) {
		super(props);

		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			locationCreated: false,
			locationName: ''
		}

	}

	componentDidMount() {
		
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		const locationCreated = this.state.locationCreated;
		this.setState({
			locationCreated: locationCreated
		});
	}

	// Add Group
	handleCreateLocationSubmit = async (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		console.log(formData);
		const data = {
			"locationName": formData.locationName
		}
		this.addNewLocation(data);
	}

	addNewLocation = (data) => {
		console.log(data);
		return Fetcher.post(this.state.apiPath + '/api/obd/location', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let locationCreated = false;
				if (res.message === 'Parameters Incorrect' || 'Location Name already exists') {
					Notify.notify(res.message);
				} else {
					locationCreated = true;
					Notify.notify(res.message);
				}
				this.setState({
					locationCreated: locationCreated
				})

			})
			.catch(err => {
				console.log('Error in Creating Location', err);
				Notify.notify('Error in Creating Location');
				this.setState({
					locationCreated: false
				})
			});
	}

	render() {

		if (this.state.locationCreated === true) {
			return <Redirect to={'/location-list/'} />
		}

		let submitButtonlabel = 'Create Location';

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleCreateLocationSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Create Location</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={12}>
										<Form.Group className="form-group">
											<Form.Control required name="locationName" type="text" placeholder="Location Name" onChange={e => this.setvalue(e)} value={this.state.locationName} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default CreateLocation;