import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal,Tabs, Tab,Spinner } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep , isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import ReactPlayer from 'react-player';

class AddVoiceFile extends Component {
	constructor(props) {
		super(props);
		this.promptFileInput = React.createRef();
		let sitePath;
		let filePath;
		if (props.app.mode === 'dev') {
			sitePath = 'http://core-php.local';
			filePath = 'http://core-php.local/';
		} else {
			const protocol = window.location.protocol;
			const slashes = protocol.concat("//");
			sitePath = slashes.concat(window.location.hostname);

			//filePath = 'https://obdapi.noblestack.co/assets/voice/';
		}
		this.state = {
			promptFileType: 'newPromptFile',
			promptFileInput: this.promptFileInput,
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			promptUploaded: false,
			fileName: '',
			tabStatusVoice : true,
			tabStatusTTS : false,
			langauge : '',
			gender : '',
			textScript : '',
			totalCharacter : 0,
			lang : '',
			buttonDisability : true,
			submitDisability : false,
			inputDisabilty : false,
			ttsId : '',
			ttsDiscard : false,
			confirmDiscard : false,
			downloadUrl : '',
			showListenModal :false,
			downloadURL : '',
			promptDuration : '',
			ttsModule : false,
			listenUrl : '',
			showListenModalTTSM : false,
			responseCharacterCount : '',
			voiceList : 'voiceList',
			ttsList : '',
			saveTTS : false,
			submitModal : false,
			submitLoader : false,
			promptCatList : []
		}

	}

	componentDidMount() {


	Fetcher.get(this.props.app.apiPath + "/api/obd/user/profile/" + this.props.app.userId)
      .then((res) => {
        console.log(res);
        let modules = res.modules;
        if (modules.length > 0) {
          for (let i = 0; i <= modules.length; i++) {
            if (modules[i].moduleId === 7) {
                
				this.setState({
                       ttsModule : true
				});
            }
			
          }
        }
      })
      .catch((err) => {
        console.log("Error in fetching Users data", err);
      });

	  Fetcher.get(this.props.app.apiPath + "/api/obd/prompt/cat")
      .then((res) => {
        console.log(res);
            this.setState({
				promptCatList : res
			})
        
      })
      .catch((err) => {
        console.log("Error in fetching Prompt Cat List", err);
      });




	//   const data = {
	// 	"ttsText":"Helloo",
	// 	"gender":"MALE",
	// 	"promptName":"test1",
	// 	"characterCount":"6",
	// 	"lang":"en-IN",
	// 	"userId":500002
	//   }
	//   this.sendPostRequest(data);

	}


	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
		if(e.target.name === 'langauge'){
			console.log("JJJ");
			this.setState({lang : this.state.langauge});
		}

	}

	cancelModal = (e) => {
          console.log("Cancel");
		  this.setState({
                showListenModalTTSM : false
		  });
	}

	settextvalue = (e) => {
       
		 const newText = e.target.value;
        
		 const englishRegex = /^[a-zA-Z0-9\s.,!?;:'"(){}\[\]@#$%^&*+-=]*$/;
    
         const hindiRegex = /^[\u0900-\u097F0-9\s.,!?;:'"(){}\[\]@#$%^&*+-=]*$/;
		 const {langauge} = this.state;
         console.log("LAng : "+langauge);


        if (langauge === 'en' && !englishRegex.test(newText)) {
            return; 
        }else if (langauge === 'hi' && !hindiRegex.test(newText)) {
			console.log("hheeee");
            return; 
        }

         this.setState({
               textScript: newText,
               totalCharacter: newText.length // Update character count
         });

	}

	setUploadType = (type, uploadTypeKey) => {
		this.setState({
			[uploadTypeKey]: type,
		});
	}

	setMessageShow = (status) => {
		console.log("cancel");
		this.setState({
			showListenModalTTSM: false
		});
	}

	onFileChangeHandler = event => {
		//if ( this.checkMimeType( event ) && this.checkFileSize( event ) ) {
		if (this.checkMimeType(event)) {

		}
	}


	getFileType = type => {

		switch(type){

			case 'audio/mpeg':
				return 'mp3';
				break;
			case 'audio/wav':
				return 'wav';
				break;
			default:
				return null;

		}

	}

	confirmDiscard = (status) => {
		this.setState({
			confirmDiscard: status
		});
	}

	okTTS = () => {
		this.setState({
			submitModal : false
		})
	}

	discardTTS = () => {
        
		const formData = cloneDeep(this.state);

		if(formData.ttsId ==''){
			Notify.notify('TTS ID Not Found');
			return;
		}
        
		const data = {
			 "userId":this.props.app.userId,
             "status": 2,
			 "id" : formData.ttsId,
			 "characterCount" : formData.responseCharacterCount
		}
		 
		return Fetcher.post(this.props.app.apiPath+ '/api/obd/change/tts/status', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let dis = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					dis = true
					Notify.notify(res.message);
				}
				this.setState({
                   ttsDiscard : dis
				});
				
			})
			.catch(err => {
				console.log('Error while Discarding  TTS', err);
				Notify.notify('Error while Discarding TTS');
			});

	}


	save = () => {
        
		const formData = cloneDeep(this.state);

		if(formData.downloadURL == ''){
			Notify.notify('Download URL cannot be empty');
			return;
		}

		if(formData.promptDuration == ''){
			Notify.notify('Prompt Duration cannot be empty');
			return;
		}

		if(formData.responseCharacterCount ==''){
			Notify.notify('Character count cannot be empty');
			return;
		}

		if(formData.ttsId ==''){
			Notify.notify('TTS ID Not Found');
			return;
		}

		const data = {
			"id" : formData.ttsId,
            "userId" : this.props.app.userId,
            "status" : 1,
            "dowmloadUrl" : formData.downloadURL,
            "promptName" : formData.fileName,
            "promptDuration" : formData.promptDuration,
			"characterCount" : formData.responseCharacterCount,
			"category":formData.promptCategory
		}
         //this.props.app.apiPath
		return Fetcher.post(this.props.app.apiPath+ '/api/obd/change/tts/status', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let sa = false;

				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					sa = true;
					Notify.notify(res.message);

				}

				this.setState({
                      saveTTS : sa
				});
				
			})
			.catch(err => {
				console.log('Error while Saving TTS', err);
				Notify.notify('Error while Saving TTS TTS');
			});
		

	}

	listenTTS = () => {
        
		// const formData = cloneDeep(this.state);

		// if(formData.gender == ''){
		// 	Notify.notify('Please select Gender');
		// 	return;
		// }

		// if(formData.langauge == ''){
		// 	Notify.notify('Please select Language');
		// 	return;
		// }

		// if(formData.textScript == ''){
		// 	Notify.notify('Please add Test To Speach');
		// 	return;
		// }

        // if(formData.fileName == ''){
		// 	Notify.notify('Please add Filename');
		// 	return;
		// }


		// const data = {
		// 	 "userId":this.props.app.userId,
        //      "gender": formData.gender,
        //      "textScript": formData.textScript,
        //      "characterCount": formData.totalCharacter,
        //      "language": (formData.langauge == 'hi' ? 'hi-IN' : 'en-IN'),
        //      "filename": formData.fileName
		// }

        // Fetcher.post(this.state.apiPath+ '/api/obd/convertion/texttospeech', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
		// 	.then(res => {
		// 		if (res.message === 'Parameters Incorrect') {
		// 			Notify.notify(res.message);
		// 		} 
		// 		else {
                    
		// 			let dUrl = '';
		// 			let pDuration = '';
		// 			let resCC ='';
		// 			if(res.status == 'success'){
        //                   dUrl = res.downloadUrl;
		// 				  pDuration = res.promptDuration;
		// 				  resCC = res.characterCount;
		// 			}else{
		// 				Notify.notify(res.msg);
        //                 this.deleteTTS();
		// 				return;
		// 			}
                     
		// 			this.setState({
        //                 downloadURL : dUrl,
		// 				promptDuration : pDuration,
		// 				responseCharacterCount : resCC
		// 			});

					
		// 			this.showListenModalTTS(this.state.downloadURL);
		// 		}
		// 	})
		// 	.catch(err => {
		// 		console.log('Error in Add TTS', err);
		// 		Notify.notify('Error in Add TTS');
		// 	});
		this.showListenModalTTS(this.state.downloadURL);
          
	}

	showListenModalTTS = (prompturl) => {
		console.log("hhhhccc"+prompturl);
		let url = prompturl;
		this.setState( {
			showListenModalTTSM: true,
			modalHeading: 'Listen TTS',
			modalContent: <ReactPlayer url={this.state.downloadURL} playing />,
			copied: false,
			listenUrl : url

		} )
	}

	setMessageShow = ( status ) => {
		this.setState( {
			showListenModal: false,
		} );
	}


	deleteTTS = () => {
		const ttsID = this.state.ttsId;
		const data = {

		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/tts/' + ttsID, { headers: { "Content-type": "application/json" }, method: 'DELETE', body: JSON.stringify(data) })
			.then(res => {
				this.refreshPage();
			})
			.catch(err => {
				console.log('Error in Deleting TTS', err);
			});
	}

	refreshPage = () => {
		this.setState({ loading: true });
		setTimeout(() => {
			window.location.reload(false);
		}, 500);
	}
	

	// Add Voice File

	setUploadType = (type, uploadTypeKey) => {
		this.setState({
			[uploadTypeKey]: type,
		});
		if(type == 'voiceList'){
			console.log("hereee");
			this.setState({
                 tabStatusVoice : true,
				 tabStatusTTS : false
			});
			
		}else if(type == 'ttsList'){
			this.setState({
                 tabStatusTTS : true,
				 tabStatusVoice : false
			});
		}
	}


	sendPostRequest = (data) => {
        fetch('https://tts.nspl.cloud/texttospeech', {
            headers: {
                "Content-Type": "application/json"
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(response => {
            // Check if the response status is OK (status code 200-299)
            if (response.ok) {
                // Parse the response as JSON
				this.setState({
					dowmloadURL : response.downloadUrl
				})
                return response.json();
            } else {
                // Throw an error if the response status is not OK
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        })
        .then(res => {
            // Handle the parsed JSON data
                    let dUrl = '';
					let pDuration = '';
					if(res.status == 'success'){
                          dUrl = res.downloadUrl;
						  pDuration = res.promptDuration;
					}else{
						Notify.notify(res.msg);
                        this.deleteTTS();
						return;
					}
                     
					this.setState({
                        downloadURL : dUrl,
						promptDuration : pDuration,
                        //showListenModalTTS : true
					});

					this.showListenModalTTS(this.state.downloadURL);
        })
        .catch(error => {
            // Handle network or parsing errors
           
            console.error('Error While Fetching API', error);
            Notify.notify('Error While Fetching API');
        });
    };


	handleAddVoiceFileSubmitbkp = (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		if(formData.tabStatusVoice){
		const promptFile = formData.promptFileInput.current.files[0];
		const fileType = this.getFileType(promptFile.type);
		const fd = new FormData();
		fd.append('waveFile', promptFile);
		fd.append('userId', formData.userId);
		fd.append('fileName', formData.fileName);
		fd.append('promptCategory', formData.promptCategory);
		fd.append('fileType', fileType);

		return Fetcher.post(formData.apiPath + '/api/obd/promptupload', { method: 'POST', body: fd })
			.then(res => {
				let promptUploaded = false;
				if (res.message === 'Parameters Incorrect' || res.message === 'Voice file name already exists, Please choose another name') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
					promptUploaded = true;
				}
				this.setState({
					promptUploaded: promptUploaded
				})
			})
			.catch(err => {
				console.log('Error in uploading Prompt File to Server', err);
				Notify.notify('Error in uploading Prompt File to Server');
			});
		}else if (formData.tabStatusTTS){

            const data = {
				  "language" : (formData.langauge == 'hi' ? 'hi-IN' : 'en-IN'),
                  "gender" : formData.gender,
                  "textScript" : formData.textScript,
                  "category" : formData.promptCategory,
                  "filename" : formData.fileName,
                  "characterCount" : formData.totalCharacter,
                  "userId" : this.props.app.userId
			}
            // this.state.apiPath
			return Fetcher.post(this.state.apiPath+ '/api/obd/add/tts', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let ttsAdded = false;
				let disableButton = true;
				let submitButton = false;
				let inputDisable = false;
				let tId = '';
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {

					if(res.ttsId=='' || res.ttsId == undefined){
						Notify.notify(res.message);

					}else{

					ttsAdded = true;	
					disableButton = false;
					submitButton = true;
					inputDisable = true;
					tId = res.ttsId;
					Notify.notify(res.message);
					}
				}
				this.setState({
					buttonDisability : disableButton,
					submitDisability : submitButton,
					inputDisabilty : inputDisable,
					ttsId : tId
				})
				
			})
			.catch(err => {
				console.log('Error in Add TTS', err);
				Notify.notify('Error in Add TTS');
			});

			
		}
	}


	handleAddTTSSubmit = (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);

		console.log("???????????????>>>>>>>>>>>>>>>");

		this.setState({
                submitModal : true,
				submitLoader : true
		});
        
		if(formData.gender == ''){
			Notify.notify('Please select Gender');
			return;
		}

		if(formData.langauge == ''){
			Notify.notify('Please select Language');
			return;
		}

		if(formData.textScript == ''){
			Notify.notify('Please add Test To Speach');
			return;
		}

        if(formData.fileName == ''){
			Notify.notify('Please add Filename');
			return;
		}

		if(formData.category == ''){
			Notify.notify('Please select Category');
			return;
		}


		const data = {
			 "userId":this.props.app.userId,
             "gender": formData.gender,
             "textScript": formData.textScript,
             "characterCount": formData.totalCharacter,
             "language": (formData.langauge == 'hi' ? 'hi-IN' : 'en-IN'),
             "filename": formData.fileName,
			"category" : formData.promptCategory
		}
         
		//this.state.apiPath
        Fetcher.post(this.state.apiPath+ '/api/obd/convertion/texttospeech', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {

				let ttsAdded = false;
				let disableButton = true;
				let submitButton = false;
				let inputDisable = false;

				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				    this.setState({
						submitLoader : false,
                        submitModal : false
					})
				} 
				else {
                    
					let dUrl = '';
					let pDuration = '';
					let resCC ='';
					let tID = '';
					let tLoad = true;
					if(res.status == 'success'){
                          dUrl = res.downloadUrl;
						  pDuration = res.promptDuration;
						  resCC = res.characterCount;
						  tID = res.ttsId;
						  ttsAdded = true;	
				          disableButton = false;
					      submitButton = true;
					      inputDisable = true;
						  tLoad = false
					      Notify.notify(res.message);
					}else if(res.status == 'fail'){

						if(res.msg === "Enough credits not available"){
							tLoad = false;
							Notify.notify(res.msg);
							setTimeout(() => {
			                       window.location.reload(false);
		                    }, 1000);
							 this.setState({
						        submitLoader : false,
                                submitModal : false
				        	})
							return;
						}

					}
                     
					this.setState({
						submitLoader : tLoad,
                        downloadURL : dUrl,
						promptDuration : pDuration,
						responseCharacterCount : resCC,
						ttsId : tID,
						buttonDisability : disableButton,
					    submitDisability : submitButton,
					    inputDisabilty : inputDisable,
					});

				}
			})
			.catch(err => {
				console.log('Error While TTS Convertion', err);
				Notify.notify('Error While TTS Convertion');
			});







		

            // const data = {
			// 	  "language" : (formData.langauge == 'hn' ? 'hn-IN' : 'en-IN'),
            //       "gender" : formData.gender,
            //       "textScript" : formData.textScript,
            //       "category" : formData.promptCategory,
            //       "filename" : formData.fileName,
            //       "characterCount" : formData.totalCharacter,
            //       "userId" : this.props.app.userId
			// }
            // this.state.apiPath
			// return Fetcher.post(this.state.apiPath+ '/api/obd/add/tts', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			// .then(res => {
			// 	let ttsAdded = false;
			// 	let disableButton = true;
			// 	let submitButton = false;
			// 	let inputDisable = false;
			// 	let tId = '';
			// 	if (res.message === 'Parameters Incorrect') {
			// 		Notify.notify(res.message);
			// 	} else {

			// 		if(res.ttsId=='' || res.ttsId == undefined){
			// 			Notify.notify(res.message);

			// 		}else{

			// 		ttsAdded = true;	
			// 		disableButton = false;
			// 		submitButton = true;
			// 		inputDisable = true;
			// 		tId = res.ttsId;
			// 		Notify.notify(res.message);
			// 		}
			// 	}
			// 	this.setState({
			// 		buttonDisability : disableButton,
			// 		submitDisability : submitButton,
			// 		inputDisabilty : inputDisable,
			// 		ttsId : tId
			// 	})
				
			// })
			// .catch(err => {
			// 	console.log('Error in Add TTS', err);
			// 	Notify.notify('Error in Add TTS');
			// });
	}


	handleAddVoiceFileSubmit = (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		const promptFile = formData.promptFileInput.current.files[0];
		const fileType = this.getFileType(promptFile.type);
		const fd = new FormData();
		fd.append('waveFile', promptFile);
		fd.append('userId', formData.userId);
		fd.append('fileName', formData.fileName);
		fd.append('promptCategory', formData.promptCategory);
		fd.append('fileType', fileType);

		return Fetcher.post(formData.apiPath + '/api/obd/promptupload', { method: 'POST', body: fd })
			.then(res => {
				let promptUploaded = false;
				if (res.message === 'Parameters Incorrect' || res.message === 'Voice file name already exists, Please choose another name') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
					promptUploaded = true;
				}
				this.setState({
					promptUploaded: promptUploaded
				})
			})
			.catch(err => {
				console.log('Error in uploading Prompt File to Server', err);
				Notify.notify('Error in uploading Prompt File to Server');
			});
		
	}

	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	getFileNameFromUrl = (url) => {
		var n = url.lastIndexOf('/');
		return url.substring(n + 1);
	}

	checkMimeType = (event) => {
		let files = event.target.files
		let allowedTypes = ['text/plain', 'text/csv'];
		if (event.target.name === 'newContactsFile') {
			allowedTypes = ['text/plain', 'text/csv'];
		} else {
			allowedTypes = ['audio/wav', 'audio/mpeg'];
		}
		let err = [] // create empty array

		for (var x = 0; x < files.length; x++) {
			if (allowedTypes.every(type => files[x].type !== type)) {
				err[x] = files[x].type + ' is not a supported format\n';
				// assign message to array
			}
		};
		for (var z = 0; z < err.length; z++) { // loop create toast massage
			event.target.value = null;
		}
		return true;
	}

	checkFileSize = (event) => {
		let allowedSize = 1;
		if (event.target.name === 'newContactsFile') {
			allowedSize = 20;
		}

		let files = event.target.files
		let err = [];
		for (var x = 0; x < files.length; x++) {
			if (((files[x].size / 1024 / 1024).toFixed(2)) > allowedSize) {
				err[x] = files[x].size + ' is too large, please pick a smaller file\n';
			}
		};
		for (var z = 0; z < err.length; z++) {
			event.target.value = null;
		}
		return true;
	}

	toggleChange = () => {
        if(this.state.tabStatusTTS){
			this.setState({ tabStatusVoice: true, tabStatusTTS: false });
		}
        
		if(this.state.tabStatusVoice){
		    this.setState({ tabStatusVoice: false, tabStatusTTS: true });
		}
    }




	render() {
		if (this.state.promptUploaded === true) {
			return <Redirect to='/voice-files' />
		}
		if (this.state.ttsAdded === true) {
			return <Redirect to='/tts-list' />
		}
        if (this.state.ttsDiscard === true) {
			return <Redirect to='/tts-list' />
		}

		if (this.state.saveTTS === true) {
			return <Redirect to='/tts-list' />
		}

		let submitButtonlabel = 'Upload File';

		let catDropdown = '';
        
		const promptCat = this.state.promptCatList;
		if (!isEmpty(promptCat) && (!('error' in promptCat))) {
		      catDropdown = promptCat.map((obj, index) => {
				        return <option value={obj.promptCategory} key={`promptfile${index}`} >{obj.promptCategory}</option>
				     })
		}else{
			catDropdown = <p>No location found</p>;
		}

		return (
  
			// <Form className="add-campaign-form" onSubmit={(e) => this.handleAddVoiceFileSubmit(e)}>
				
				<Tabs defaultActiveKey="voiceList" className="primary" onSelect={k => this.setUploadType(k, 'voiceList')}>
					<Tab eventKey="voiceList" title="Upload Voice File">
					  <Row>
					    <Col sm={12}>
						  <Form className="add-campaign-form" onSubmit={(e) => this.handleAddVoiceFileSubmit(e)}>
						   <Card>

							<Card.Header>Upload Voice File
							</Card.Header>
							<Card.Body>
								
								<Row>
									<Col sm={4}>
										<Form.Label>Upload WAV or MP3 File</Form.Label>
										<Form.Group className="form-group"  controlId="prompt-file-upload">
											<Form.Control required name="newPromptFile" onChange={this.onFileChangeHandler} ref={this.promptFileInput} type="file" />
										</Form.Group>
									</Col>
									
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group"  controlId="trunk">
											<Form.Label>Select Category</Form.Label>
											<Form.Select required name="promptCategory" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Category</option>
												<option value="welcome">Welcome</option>
												<option value="menu">Menu</option>
												<option value="noinput">No Input</option>
												<option value="wronginput">Wrong Input</option>
												<option value="thanks">Thanks</option>
												<option value="noagent">No Agent</option>
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group"  controlId="file-name">
											<Form.Label>File Name</Form.Label>
											<Form.Control required name="fileName" onChange={e => this.setvalue(e)} value={this.state.fileName} type="text" placeholder="File Name" />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>
                          </Form>
					   </Col>
				      </Row>				 
					</Tab>
					<Tab eventKey="ttsList" title="Add TTS">

					  <Row>
				       	<Col sm={12}>
						 <Form className="add-campaign-form" onSubmit={(e) => this.handleAddTTSSubmit(e)}>
		
						  <Card>
							<Card.Header>Add TTS
							</Card.Header>
							<Card.Body>
								
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											
											<Form.Select className="dd" required name="langauge" onChange={e => this.setvalue(e)} as="select" disabled={this.state.inputDisabilty}>
												<option value="">Select Language</option>
												<option value="en">English</option>
												<option value="hi">Hindi</option>
											</Form.Select>
										</Form.Group>
									</Col>

									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											
											<Form.Select className="dd" required name="gender" onChange={e => this.setvalue(e)} as="select" disabled={this.state.inputDisabilty}>
												<option value="">Select Gender</option>
												<option value="MALE">MALE</option>
												<option value="FEMALE">FEMALE</option>
											</Form.Select>
										</Form.Group>
									</Col>

									{/* <Col sm={4}>
										<Form.Group className="form-group"  controlId="file-name">
											 <Form.Label>File Name</Form.Label> 
											<Form.Control required name="fileName" onChange={e => this.setvalue(e)} value={this.state.fileName} type="text" placeholder="File Name" disabled={this.state.inputDisabilty} />
										</Form.Group>
									</Col> */}
									
								</Row>
								<Row>
                                    

									<Col sm={4}>
										<Form.Group className="form-group"  controlId="trunk">
											<Form.Select required name="promptCategory" onChange={e => this.setvalue(e)} as="select" disabled={this.state.inputDisabilty}>
												<option value="">Select Category</option>
												{catDropdown}
											</Form.Select>
										</Form.Group>
									</Col>

									<Col sm={4}>
										<Form.Group className="form-group"  controlId="file-name">
											{/* <Form.Label>File Name</Form.Label> */}
											<Form.Control required name="fileName" onChange={e => this.setvalue(e)} value={this.state.fileName} type="text" placeholder="File Name" disabled={this.state.inputDisabilty} />
										</Form.Group>
									</Col>
									{/* <Col sm={4}>
										<Form.Group className="form-group"  controlId="file-name">
											<Form.Label>File Name</Form.Label>
											<Form.Control required name="fileName" onChange={e => this.setvalue(e)} value={this.state.fileName} type="text" placeholder="File Name" disabled={this.state.inputDisabilty} />
										</Form.Group>
									</Col> */}
								</Row>

								<Row>
									<Col sm={8}>

									 <Form.Group className="form-group" controlId="text-area">
                                            <Form.Label>Text Script</Form.Label>
                                            <Form.Control required rows={3} as="textarea" name="textScript"  placeholder="Enter Text Script" value={this.state.textScript} onChange={e => this.settextvalue(e)} disabled={this.state.inputDisabilty} />
                                     </Form.Group>
									
									
									</Col>
								     
								</Row>
								<Row>
									<Col>
									  <Form.Text className="text-muted" style={{ color: 'red' }} >
									   Entered Text Script Length : {this.state.totalCharacter}
									   </Form.Text>
									</Col>
								</Row>
								<br></br>
                                
								<Row>
								<Col>
                                
								<Button variant="primary" type="submit" className="ml-3" disabled={this.state.submitDisability}>
									Submit
								</Button>

								<Button onClick={this.listenTTS} className="ml-3" disabled={this.state.buttonDisability}>
                                      Listen
                                 </Button>
								 <Button onClick={(e) => { this.confirmDiscard(true) }} className="ml-3" disabled={this.state.buttonDisability}>
                                      Discard
                                 </Button>
								 <Button onClick={this.save} className="ml-3" disabled={this.state.buttonDisability}>
                                      Save
                                 </Button>
								  
								
								 </Col>

								</Row>


								<Modal size="md" show={this.state.confirmDiscard} onHide={() => this.confirmDiscard(false)}>
					               	<Modal.Header closeButton>
						               	<Modal.Title id="example-modal-sizes-title-sm">
							            	Discard TTS
							            </Modal.Title>
						        </Modal.Header>
						        <Modal.Body>
							          Are you sure you want to Discard this TTS
						        </Modal.Body>
						        <Modal.Footer>
							       <Button onClick={() => this.confirmDiscard(false)} variant="secondary">CANCEL</Button>
							       <Button onClick={this.discardTTS} variant="primary">YES</Button>
						        </Modal.Footer>
					           </Modal>



                               <Modal size="lg" show={this.state.showListenModalTTSM} onHide={() => this.cancelModal(false)}>
						            <Modal.Header closeButton>
							          <Modal.Title className="modal-heading" id="example-modal-sizes-title-sm">
								          {this.state.modalHeading}
							          </Modal.Title>
						            </Modal.Header>
						           <Modal.Body>
							           <ReactPlayer url={this.state.listenUrl} controls width='90%' height='80px' />
							       </Modal.Body>

					          </Modal>

							   <Modal show={this.state.submitModal} onHide={() => this.cancelSubmitModal(false)} className="custom-modal">
						            <Modal.Header closeButton>
							          <Modal.Title className="modal-heading" id="example-modal-sizes-title-sm">
								           TTS
							          </Modal.Title>
						            </Modal.Header>
									{(this.state.submitLoader === true) && 
									      <Modal.Body>
											 <div className="loader">
                                               <Spinner animation="border" variant="primary" />
                                             </div>

										  </Modal.Body>
								    }
									{(this.state.submitLoader === false) && 
						               <Modal.Body>
										   Listen to the TTS file, press Save if its fine. Press Discard to record again.
							           </Modal.Body>
									   
	                                }
                                    
									{(this.state.submitLoader === false) && 
									 <Modal.Footer>
							           <Button onClick={this.okTTS} variant="primary">OK</Button>
						             </Modal.Footer>
	                                }

					          </Modal>

							</Card.Body>
						</Card>
                        </Form>
					  </Col>
				     </Row>
						
					</Tab>
				</Tabs>
				
				
				
		);
	}
}

export default AddVoiceFile;