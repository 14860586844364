import React, { Component } from 'react';
import { Card, Modal, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../Helpers/fetcher.js";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './index.css';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
class Report extends Component {
	constructor( props ) {

		super( props );
		this.state = {
			reportList: [],
			sizePerPage: 10
		}
	}
	componentDidMount() {
		const savedSizePerPage = Cookies.get('downloadReportListsizePerPage');
		if (savedSizePerPage) {
			this.setState({ sizePerPage: Number(savedSizePerPage) });
		}
		const userId = this.props.app.userId;
		Fetcher.get(this.props.app.apiPath + '/api/obd/report/download/' + userId)
			.then(res => {
				this.setState({
					reportList: res
				})
			})
			.catch(err => { console.log('Error in fetching Campaigns', err) });
	}

	setMessageShow = ( status ) => {
		this.setState( {
			showModal: false,
		} );
	}
	downloadReport = (reportUrl) => {
				window.open(reportUrl, 'Download');
	}
	refreshPage = () => {
		this.setState({ loading: true });
		//window.location.reload();

		setTimeout(() => {
			window.location.reload(false);
		}, 500);
	}
	handleSizePerPageChange = (newSizePerPage) => {
		this.setState({ sizePerPage: newSizePerPage });
		Cookies.set('downloadReportListsizePerPage', newSizePerPage);
	}
	render() {

		const reportList = this.state.reportList;
		const columns = [
			{
				dataField: 'campaignId',
				text: 'Campaign Id',
				sort: true,
				hidden: false
			},
			{
				dataField: 'campaignName',
				text: 'Campaign Name',
				sort: true,
				hidden: false
			},
			{
				dataField: 'reqDate',
				text: 'Request Date',
				sort: true,
				hidden: false
			},
			{
				dataField: 'status',
				text: 'Report Status',
				sort: true,
				hidden: false
			},
			{
				dataField: 'reportType',
				text: 'Report Type',
				sort: true,
				hidden: false
			}, {
				dataField: 'reportUrl',
				text: 'DownLoad Link',
				sort: true,
				hidden: false
			}];
			
		const report = Array.isArray(reportList) ? reportList.map( ( row, index ) => {
			let reportStatus='';
			switch (row.status) {
				case '0':
					reportStatus = 'Initiated';
					break;
				case '1':
					reportStatus = 'Processing';
					break;
				case '2':
					reportStatus = 'Ready for Download';
					break;
				default:
					reportStatus = '';
					break;
			}
			return {
				campaignId: row.campaignId,
				campaignName: row.campaignName,
				reqDate: row.reqDate,
				status: reportStatus,
				reportType: row.reportType,
				reportUrl: <a key={`dreport-${index}`} className = "" onClick={() => this.downloadReport(row.reportUrl)} href="#">Download</a>
			};
		}): []

		const { SearchBar, ClearSearchButton } = Search;

		const defaultSorted = [ {
			dataField: 'reqDate',
			order: 'desc'
		} ];

		const paginationOption = {
			custom: true,
			totalSize: report.length,
			sizePerPage: this.state.sizePerPage,
			sizePerPageList: [ 10, 25, 50, 100 ],
			onSizePerPageChange: this.handleSizePerPageChange
		};

		return (
			<Card>
					<Card.Header>Report</Card.Header>
					<Card.Body>
						<div className="widget-list-table-cont">
							{/* <h3>Campaign Summary</h3> */}
							{/*{!isEmpty( this.state.report ) && <PaginationProvider pagination={paginationFactory( paginationOption )}>*/}
							<PaginationProvider pagination={paginationFactory(paginationOption)}>
								{({
									paginationProps, paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="reqDate"
										data={report}
										columns={columns}
										search
										bootstrap4

									>
										{
											props => (
												<div className="">
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													<Button id="refresh" type="button" className="float-right btn btn-default btn-primary ml-2" onClick={() => this.refreshPage()}>Refresh</Button>
													<hr/>
													<BootstrapTable defaultSorted={defaultSorted} bordered={true} striped={true} condensed wrapperClasses="table-responsive" classes="widgetlist-table" {...props.baseProps} {...paginationTableProps} noDataIndication={() => (<div>{'No data available'}</div>)} />
													<PaginationListStandalone {...paginationProps} />
												</div>
											)}
									</ToolkitProvider>
								)}
							</PaginationProvider>
						</div>
						<Modal size="lg" show={this.state.showModal} onHide={() => this.setMessageShow(false)}>
							<Modal.Header closeButton>
								<Modal.Title className="modal-heading" id="example-modal-sizes-title-sm">
									{this.state.modalHeading}
								</Modal.Title>

							</Modal.Header>
							<Modal.Body>
								<ReactPlayer url={`${this.props.app.apiPath}/rec/${this.state.selecteduuID}.wav`} controls width='90%' height='80px' />
							</Modal.Body>

						</Modal>

						<Modal size="md" show={this.state.confirmCallNow} onHide={() => this.confirmCallNow(false, '')}>
							<Modal.Header closeButton>
								<Modal.Title id="example-modal-sizes-title-sm">
									Call Now
								</Modal.Title>

							</Modal.Header>
							<Modal.Body>
								Are you sure you want to call this number?
							</Modal.Body>
							<Modal.Footer>
								<Button onClick={() => this.confirmCallNow(false, '')} variant="secondary">CANCEL</Button>
								<Button onClick={this.callNow} variant="primary">YES</Button>
							</Modal.Footer>
						</Modal>

					</Card.Body>
				</Card>

		);
	}
}

export default Report;