import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "./index.css";

class AddVoiceBlacklist extends Component {
	constructor(props) {
		super(props);
		let sitePath;
		let filePath;
		
		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);
		this.state = {
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			dndAdded: false,
			mobile: '',
			addTo : ''
		}

	}

	componentDidMount() {

	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
		});
	}

	// Add Plan
	handleAddVoiceBlacklist = (event, operation) => {
		event.preventDefault();

		if (!this.state.mobile) {
			Notify.notify('Please enter a mobile number.');
			return;
		}

		if (!this.state.addTo) {
			Notify.notify('Please Select Add to option');
			return;
		}

		const formData = cloneDeep(this.state);
		console.log(formData);

		let data = {};
		let urlPrefix = "";
        
		if(formData.addTo == 0){

			data = {
			"userId": formData.userId,
			"mobile": formData.mobile
	      	};
            
			urlPrefix = "blacklist";

		}else if(formData.addTo == 1){

			data = {
			"userId": formData.userId,
			"mobile": formData.mobile,
			"operation": operation
		    }; 

			urlPrefix = "addDND";
		}


		return Fetcher.post(formData.apiPath + '/api/obd/'+urlPrefix, { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				
				
				let dndAdded = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
					dndAdded = true;
				}
				this.setState({
					dndAdded: dndAdded
				})
				if(dndAdded){
					this.setState({
						mobile: '',
						addTo:''
					})
				}
			})
			.catch(err => {
				console.log('Error in Creating '+this.state.addTo, err);
				Notify.notify('Error in Creating '+this.state.addTo);
			});
	}

	

	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	render() {
		// if (this.state.dndAdded === true) {
		// 	return <Redirect to='/voice-blacklist' />
		// }
		let addButtonlabel = 'Add';
		let removeButtonlabel = 'Remove';

		return (

			<Form className="add-campaign-form">
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Add Number In Blacklist OR DND</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group"  controlId="plan-name">
											<Form.Label>Mobile No.</Form.Label>
											<Form.Control required name="mobile" onChange={e => this.setvalue(e)} value={this.state.mobile} type="number" placeholder="Mobile No." />
										</Form.Group>
									</Col>

									<Col sm={4}>
					                	<Form.Group className="form-group required control-label">
						                	<Form.Label>Add to</Form.Label>
											<Form.Select className="dd" required name="addTo" value={this.state.addTo} onChange={e => this.setvalue(e)} as="select">
						                  		<option value="">Select Add to</option>
								            	<option value="0">BLACKLIST</option>
								            	<option value="1">DND</option>
							                </Form.Select>
							            </Form.Group>
					                </Col>
								</Row>
								<Button variant="primary" className="mr-2" type="button" onClick={(e) => this.handleAddVoiceBlacklist(e, 'add')}>{addButtonlabel}</Button>
								{/* <Button variant="secondary" type="button" onClick={(e) => this.handleAddVoiceBlacklist(e, 'remove')}>{removeButtonlabel}</Button> */}
							</Card.Body>
						</Card>

					</Col>
					
				</Row>
			</Form>
		);
	}
}

export default AddVoiceBlacklist;