import React, { Component } from 'react';
import { Button, Card, Col, Form, Row, Modal, Dropdown } from 'react-bootstrap';
import { cloneDeep, isEmpty, isNull } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Notify } from "../../../Helpers/notification.js";

import "../index.css";
class CreateUser extends Component {
	constructor(props) {
		super(props);
		this.logoInput = React.createRef();
		this.state = {
			username: '',
			password: '',
			planId: '',
			confirmPassword: '',
			name: '',
			emailid: '',
			number: '',
			address: '',
			company: '',
			pincode: '',
			credits: '',
			domain: '',
			channels: 0,
			parentChannels: 0,
			userChannels: 0,
			logoInput: this.logoInput,
			logo: '',
			redirectFlag: false,
			userType: '',
			planList: [],
			accountType: '',
			parentAccount: '',
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			channelsAvailable: '',
			sendDate: new Date(),
			logoDomain: false,
			moduleList: [],
			parentModuleList: [],
			moduleId: '',
			locationList: [],
			groupList: [],
			locationId: '',
			groupId: '',
			groupName: '',
			groupRows: [],
			locationRows: [],
			groupsList: '',
			locationsList: '',
			margin: '',
			interval: '',
			threshold: '',
			tier : '',
			isSub : false,
			showSubmission: false,
			setting : false,
			pulseDuration: 0,
			Setting1: false,
			Setting2: false,
			Setting3: false,
			Setting4: false,
			margin1: '',
			interval1: '',
			threshold1: '',
			starting_range1: '',
			ending_range1: '',
			margin2: '',
			interval2: '',
			threshold2: '',
			starting_range2: '',
			ending_range2: '',
			margin3: '',
			interval3: '',
			threshold3: '',
			starting_range3: '',
			ending_range3: '',
			margin4: '',
			interval4: '',
			threshold4: '',
			starting_range4: '',
			ending_range4: '',
			loading: false,
			selectedModules: [],
			selectedModuleId: '',
			ttsCredits : '',
			ttsCreditsFlag : false
		};

	}
	componentDidMount() {
		const apiPath = this.state.apiPath;
		const userId = this.state.userId;
		const parent = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
					parentChannels: res.channels,
					parentAccount: res.accountType,
					parentModuleList: res.modules,
					showSubmission: res.sub === 1 ? false : true,
				})
				if (res.location && res.location.length > 0) { 
					const locationsList = res.location.map(location => ({
					  locationId: location.locationId.toString(), 
					  locationName: location.locationName
					}));
					this.state.locationsList = {
						"locationsList": locationsList
					}
				}
				if (res.group && res.group.length > 0) { 
					const groupsList = res.group.map(group => ({
						groupId: group.groupId.toString(), 
						groupName: group.groupName
					}));
					this.state.groupsList = {
						"groupsList": groupsList
					}
				}
			})
			.catch(err => { console.log('Error in fetching Users data', err) });

		Fetcher.get(apiPath + '/api/obd/user/channels/' + userId)
			.then(res => {
				this.setState({
					userChannels: res.userChannels
				})
			})
			.catch(err => { console.log('Error in fetching Users data', err) });

		// Get Plan List
		Fetcher.get(apiPath + '/api/obd/plans/list/' + userId)
			.then(res => {
				this.setState({
					planList: res
				})
			})
			.catch(err => { console.log('Error in fetching Plans', err) });
		
		Fetcher.get(apiPath + '/api/obd/locations')
			.then(res => {
				this.setState({
					locationList: res
				})
			})
			.catch(err => { console.log('Error in fetching Location List', err) });
		
		Fetcher.get(apiPath + '/api/obd/groups')
			.then(res => {
				this.setState({
					groupList: res
				})
			})
			.catch(err => { console.log('Error in fetching Group List', err) });
		
		// Get Module List
		Fetcher.get(apiPath + '/api/obd/module')
			.then(res => {
				this.setState({
					moduleList: res
				})
			})
			.catch(err => { console.log('Error in fetching Modules', err) });
	}

	sendLaterDateChange = date => {
		this.setState({
			sendDate: date
		});
	};
	
	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}
	handleSelect = function (selectedItems) {
		const servicesSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			servicesSelected.push(selectedItems[i].value);
		}
		this.setState({
			moduleId: servicesSelected.toString()
		});
	}
	
	handleModuleSelect = () => {
		const { selectedModules, selectedModuleId } = this.state;
        
		console.log("IDD : :"+this.state.selectedModuleId);
		if(this.state.selectedModuleId == 7){
			this.setState({
				ttsCreditsFlag : true
			})
		}

		if (selectedModuleId !== '') {
			if(selectedModules.includes(selectedModuleId))
			{
					Notify.notify('This Module is already selected');
					return;
			}
			const updatedModules = [...selectedModules, selectedModuleId]
			this.setState({
				selectedModules: updatedModules,moduleId:updatedModules.toString()
			});
			
		}
		else {
			Notify.notify("Please select Module");
		}
	};

	handleRemoveModuleRow = (moduleId) => {
		const { selectedModules } = this.state;
		if(moduleId == 7){
			this.setState({
				ttsCreditsFlag : false,
				ttsCredits : ''
			})
		}
		const updatedModules = selectedModules.filter(id => id !== moduleId);
		this.setState({
			selectedModules: updatedModules,
			moduleId:updatedModules.toString()
		});
	};
	
	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}
	toggleCheckedChange = (e, name) => {
		this.setState({
			[name]: e.target.checked,
		});
	};
	hideModal = () => {
		this.setState({
			showModal: false,
			redirectFlag: this.state.redirectFlag
		});
	}

	handleAddGroupRow = () => {
		if (this.state.groupId !== '') {
			let groupName = this.state.groupId.substring(this.state.groupId.lastIndexOf(',')+1,this.state.groupId.length);
			let groupId = this.state.groupId.substring(0,this.state.groupId.lastIndexOf(','));
			const item = {
				groupId: groupId,
				groupName: groupName
			};
			const checkGroup = obj => obj.groupId === groupId;
			if(this.state.groupRows.some(checkGroup))
			{
					Notify.notify('This Group is already selected');
					return;
			}
			this.state.groupRows.push(item);
			this.setState({
				groupRows: this.state.groupRows
			});
			this.state.groupsList = {
				"groupsList": this.state.groupRows
			}
		}
		else {
			Notify.notify("Please select Group");
		}
	};

	handleAddLocationRow = () => {
		if (this.state.locationId !== '') {
			let locationName = this.state.locationId.substring(this.state.locationId.lastIndexOf(',')+1,this.state.locationId.length);
			let locationId = this.state.locationId.substring(0,this.state.locationId.lastIndexOf(','));
			const item = {
				locationId: locationId,
				locationName: locationName
			};
			const checkLocation = obj => obj.locationId === locationId;
			if(this.state.locationRows.some(checkLocation))
			{
					Notify.notify('This Location is already selected');
					return;
			}
			this.state.locationRows.push(item);
			this.setState({
				locationRows: this.state.locationRows
			});
			this.state.locationsList = {
				"locationsList": this.state.locationRows
			}
		}
		else {
			Notify.notify("Please select Location");
		}
	};

	handleRemoveGroupRow = (index) => {
		const newGroupRows = [...this.state.groupRows];
		newGroupRows.splice(index, 1);
		this.setState({
			groupRows: newGroupRows
		});
		this.state.groupsList = {
			"groupsList": newGroupRows
		}
	};

	handleRemoveLocationRow = (index) => {
		const newLocationRows = [...this.state.locationRows];
		newLocationRows.splice(index, 1);
		this.setState({
			locationRows: newLocationRows
		});
		this.state.locationsList = {
			"locationsList": newLocationRows
		}
	};

	handleChangeGroupRow = idx => e => {
		const { name, value } = e.target;
		const groupRows = [...this.state.groupRows];
		groupRows[idx] = {
			[name]: value
		};
		this.setState({
			groupRows
		});
	};

	handleChangeLocationRow = idx => e => {
		const { name, value } = e.target;
		const locationRows = [...this.state.locationRows];
		locationRows[idx] = {
			[name]: value
		};
		this.setState({
			locationRows
		});
	};

	registerUser = (event) => {
		event.preventDefault();
		this.setState({ loading: true });
		const state = cloneDeep(this.state);
		if(state.moduleId === '')
		{
			state.moduleId = null;
		}
		if (state.confirmPassword !== state.password) {
			alert('Password & Confirm Password don\'t match');
			this.setState({ loading: false });
			return;
		}
		if (state.password.length < 8) {
			alert('Password cannot be less than 8 characters');
			this.setState({ loading: false });
			return;
		}

		if(state.moduleId !== ''){
			if(state.moduleId.includes(6)){
				if(!(state.moduleId.includes(5))){
				  Notify.notify('Please also select Dedicated Cli module.');
			      this.setState({ loading: false });
			     return;	
				}
			}

		}
		// if(state.channels > state.parentChannels - state.userChannels)
		// {
		// 	alert('Channel Limit Exceeded');
		// 	return;
		// }
		const date = state.sendDate;
		let sendDateTime = this.addZero(date.getFullYear());
		sendDateTime += "-";
		sendDateTime += this.addZero((date.getMonth() + 1));
		sendDateTime += "-"
		sendDateTime += this.addZero(date.getDate());
		sendDateTime += " 23:59:59"
		/*sendDateTime += this.addZero(date.getHours());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getMinutes());
		sendDateTime += ":"
		sendDateTime += this.addZero(date.getSeconds());*/
		
		var logo = null;
		var fd = new FormData();

		let isSubValue = 0;
		if(this.state.isSub){
			isSubValue = 1;
		}

		const settings = {};
		for (let i = 1; i <= 4; i++) {
			if (i === 1 ){
            	const planList = this.state.planList;
		    	const matchingPlan = planList.find(plan => plan.planId == this.state.planId);
				if (matchingPlan && matchingPlan.pulseDuration != 1){
					this.setState({ Setting1: false });
					continue;
				}
			}
			const setting = state[`Setting${i}`];
			if (setting) {
				settings[`tier${i}`] = {
					margin: state[`margin${i}`],
					interval: state[`interval${i}`] || "",
					threshold: state[`threshold${i}`],
					startRange: state[`interval${i}`] ? "" : state[`starting_range${i}`],
					endRange: state[`interval${i}`] ? "" : state[`ending_range${i}`]
				};
				if (parseInt(state[`starting_range${i}`]) >= parseInt(state[`ending_range${i}`])) {
					alert(`For Setting${i}, starting range cannot be greater than or equal to ending range`);
					this.setState({ loading: false });
					return;
				}
				// if (i === 1 && state.pulseDuration > 1) {
				// 	alert('User Pulse Duration is Greater than 1 while applying setting for Setting1');
				// 	return;
				// }
			}
		}
		
		if(this.state.logoDomain)
		{
            if(Object.keys(settings).length !== 0){
				 logo = state.logoInput.current.files[0];
			     fd.append('username', state.username);
			     fd.append('password', state.password);
			     fd.append('planId', state.planId);
			     fd.append('name', state.name);
			     fd.append('emailid', state.emailid);
			     fd.append('number', state.number);
			     fd.append('address', state.address);
			     fd.append('company', state.company);
			     fd.append('pincode', state.pincode);
			     fd.append('logo', logo);
			     fd.append('domain', state.domain);
			     fd.append('parent', this.props.app.userId);
			     fd.append('accountType', state.accountType);
			     fd.append('userType', state.userType);
			     fd.append('expiryDate', sendDateTime);
			     fd.append('groupRows', JSON.stringify(this.state.groupsList));
			     fd.append('locationRows', JSON.stringify(this.state.locationsList));
				 fd.append('moduleId',state.moduleId);
				 fd.append('isSetting',Boolean(Object.keys(settings).length));
				 fd.append('margin',state.margin);
				 fd.append('interval',state.interval);
				 fd.append('threshold',state.threshold);
				 fd.append('sub',isSubValue);
				 fd.append('tier',state.tier);
				 fd.append('settings', JSON.stringify(settings));
				 const tc = (this.state.ttsCredits == '' ? "0" : this.state.ttsCredits);
				 fd.append('ttsCredits', tc);
			     this.saveDataWithLogo(fd);
			}
            else{
			    logo = state.logoInput.current.files[0];
			    fd.append('username', state.username);
			    fd.append('password', state.password);
			    fd.append('planId', state.planId);
			    fd.append('name', state.name);
			    fd.append('emailid', state.emailid);
			    fd.append('number', state.number);
			    fd.append('address', state.address);
			    fd.append('company', state.company);
			    fd.append('pincode', state.pincode);
			    fd.append('logo', logo);
			    fd.append('domain', state.domain);
			    fd.append('parent', this.props.app.userId);
			    fd.append('accountType', state.accountType);
			    fd.append('userType', state.userType);
			    fd.append('expiryDate', sendDateTime);
			    fd.append('groupRows', JSON.stringify(this.state.groupsList));
			    fd.append('locationRows', JSON.stringify(this.state.locationsList));
			    fd.append('moduleId',state.moduleId);
				fd.append('isSetting',Boolean(Object.keys(settings).length));
				fd.append('margin',state.margin);
				fd.append('interval',state.interval);
				fd.append('threshold',state.threshold);
				fd.append('sub',isSubValue);
				fd.append('tier',state.tier);
				fd.append('settings', JSON.stringify(settings));
				const tc = (this.state.ttsCredits == '' ? "0" : this.state.ttsCredits);
				fd.append('ttsCredits', tc);
			    this.saveDataWithLogo(fd);
			}

		}
		else
		{
			if(Object.keys(settings).length !== 0){
				const data = {
				username: state.username,
				password: state.password,
				planId: state.planId,
				name: state.name,
				emailid: state.emailid,
				number: state.number,
				address: state.address,
				company: state.company,
				pincode: state.pincode,
				parent: this.props.app.userId,
				accountType: state.accountType,
				userType: state.userType,
				expiryDate: sendDateTime,
				"groupRows": JSON.stringify(this.state.groupsList),
				"locationRows": JSON.stringify(this.state.locationsList),
				moduleId: state.moduleId,
				sub: isSubValue,
				settings: settings,
				ttsCredits: (this.state.ttsCredits == '' ? "0" : this.state.ttsCredits)
			}
			this.saveData(data);
			}
			else{
				const data = {
				username: state.username,
				password: state.password,
				planId: state.planId,
				name: state.name,
				emailid: state.emailid,
				number: state.number,
				address: state.address,
				company: state.company,
				pincode: state.pincode,
				parent: this.props.app.userId,
				accountType: state.accountType,
				userType: state.userType,
				expiryDate: sendDateTime,
				"groupRows": JSON.stringify(this.state.groupsList),
				"locationRows": JSON.stringify(this.state.locationsList),
				moduleId: state.moduleId,
				sub: isSubValue,
				ttsCredits: (this.state.ttsCredits == '' ? "0" : this.state.ttsCredits)
			}
			this.saveData(data);
			}
		}
	}
	saveDataWithLogo = (fd) => {
		// this.props.app.apiPath
		return Fetcher.post(this.props.app.apiPath + '/api/obd/register1', { method: 'POST', body: fd })
			.then(res => {
				Notify.notify(res.message);
				if(res.message === 'User Created Successfully')
				{
					this.setState({
						redirectFlag: true
					})
				}
				else {
					this.setState({
						loading: false 
					});
				}
			})
			.catch(err => {
				console.log('Error in Registering Account', err);
				this.setState({
					loading: false 
				});
				Notify.notify('Error in Registering Account');
			});

	}


	saveData = (data) => {
		// this.props.app.apiPath
		return Fetcher.post(this.props.app.apiPath + '/api/obd/register', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				if(res.message === 'User Created Successfully')
				{
					this.setState({
						redirectFlag: true
					})
				}
				else {
					this.setState({
						loading: false 
					});
				} 
			})
			.catch(err => {
				console.log('Error in Registering Account', err);
				this.setState({
					loading: false 
				});
				Notify.notify('Error in Registering Account');
			});

	}

	setMessageShow = (status) => {
		this.setState({
			showModal: false,
		});
	}

	renderSettingOption  = (optionName) => {
		const isAdmin = this.props.app.role === 'ROLE_SADMIN';
		const optionState = this.state[optionName];
		const marginValue = this.state[`margin${optionName.slice(-1)}`];
		const thresholdValue = this.state[`threshold${optionName.slice(-1)}`];
		const isTier2or3 = optionName === 'Setting2' || optionName === 'Setting3';
		let intervalValue;
        if (!isTier2or3) {
          intervalValue = this.state[`interval${optionName.slice(-1)}`];
        }
	
		return (
			<div>
				<Row>
					{isAdmin && (
						<Col sm={3}>
							<Form.Group className="form-group">
								<Form.Check
									checked={optionState}
									onChange={(e) => this.toggleCheckedChange(e, optionName)}
									custom
									inline
									name={optionName}
									label={`${optionName}`}
									id={optionName}
									type="checkbox"
								/>
							</Form.Group>
						</Col>
					)}
				</Row>
				<Row>
					{optionState && isAdmin && (
						<>
							<Col sm={4}>
								<Form.Group className="form-group required control-label">
									<Form.Select
										className="dd"
										required
										name={`margin${optionName.slice(-1)}`}
										value={marginValue}
										onChange={(e) => this.setvalue(e)}
										as="select"
									>
										<option value="">Select Margin</option>
										{[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((percent, index) => (
											<option key={index} value={index + 1}>{percent}%</option>
										))}
									</Form.Select>
								</Form.Group>
							</Col>
							{isTier2or3 && (
								<>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
										<Form.Select
                                            className="dd"
                                            required
                                            name={`starting_range${optionName.slice(-1)}`}
                                            value={this.state[`starting_range${optionName.slice(-1)}`]}
                                            onChange={(e) => this.setvalue(e)}
                                            as="select"        
                                        >        
                                            <option value="">Starting Range</option>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
                                                <option key={seconds} value={seconds}>{seconds} sec</option>
                                            ))}        
                                        </Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
										<Form.Select
                                            className="dd"
                                            required
                                            name={`ending_range${optionName.slice(-1)}`}
                                            value={this.state[`ending_range${optionName.slice(-1)}`]}
                                            onChange={(e) => this.setvalue(e)}
                                            as="select"
                                        >
                                            <option value="">Ending Range</option>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
                                              <option key={seconds} value={seconds}>{seconds} sec</option>
                                            ))}
                                        </Form.Select>
										</Form.Group>
									</Col>
								</>
							)}
							{!isTier2or3 && (
								<>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Select
												className="dd"
												required
												name={`interval${optionName.slice(-1)}`}
												value={intervalValue}
												onChange={(e) => this.setvalue(e)}
												as="select"
											>
												<option value="">Select Interval</option>
												{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
													<option key={seconds} value={seconds}>{seconds} sec</option>
												))}
											</Form.Select>
										</Form.Group>
									</Col>
								</>
							)}
							<Col sm={4}>
								<Form.Group className="form-group required control-label">
									<Form.Control
										required
										name={`threshold${optionName.slice(-1)}`}
										type="number"
										placeholder="Threshold"
										onChange={(e) => this.setvalue(e)}
										value={thresholdValue}
									/>
								</Form.Group>
							</Col>
						</>
					)}
				</Row>
			</div>
		);
	};

	render() {
		if (this.state.redirectFlag === true) {
			return <Redirect to={'/user-list/'} />
		}
		const groupRows = cloneDeep(this.state.groupRows);
		const groupsTable = Array.isArray(groupRows) ? groupRows.map((item, index) => {
			return <tr key={index}>
				<td>{groupRows[index].groupName}</td>
				<td>
					<i onClick={() => this.handleRemoveGroupRow(index)} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		}) : [];

		const locationRows = cloneDeep(this.state.locationRows);
		const locationsTable = Array.isArray(locationRows) ? locationRows.map((item, index) => {
			return <tr key={index}>
				<td>{locationRows[index].locationName}</td>
				<td>
					<i onClick={() => this.handleRemoveLocationRow(index)} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		}) : [];

		const locationList = cloneDeep(this.state.locationList);
		let locationDropdown = '';
		if (!isEmpty(locationList) && (!('error' in locationList))) {
			locationDropdown = Array.isArray(locationList) ? locationList.map((obj, index) => {
				return <option value={obj.locationId+','+obj.locationName} key={`location${index}`} >{obj.locationName}</option>
			}) : []

		} else {
			locationDropdown = <option value="" >No location found</option>
		}

		const groupList = cloneDeep(this.state.groupList);
		let groupDropdown = '';
		if (!isEmpty(groupList) && (!('error' in groupList))) {
			groupDropdown = Array.isArray(groupList) ? groupList.map((obj, index) => {
				return <option value={obj.groupId+','+obj.groupName} key={`group${index}`} >{obj.groupName}</option>
			}) : []

		} else {
			groupDropdown = <option value="" >No group found</option>
		}
		const planList = cloneDeep(this.state.planList);
		let matchingPlan;
		if(planList && planList.length > 0){
			matchingPlan = planList.find(plan => plan.planId == this.state.planId);
		} 
		let planDropdown = '';
		if (!isEmpty(planList) && (!('error' in planList))) {
			planDropdown = Array.isArray(planList) ? planList.map((obj, index) => {
				return <option value={obj.planId} key={`planid${index}`} >{obj.planName}</option>
			}) : []

		} else {
			planDropdown = <option value="" >No Plan found</option>
		}
		const moduleList = cloneDeep(this.state.moduleList);
		let moduleDropdown = '';
		if (!isEmpty(moduleList) && (!('error' in moduleList))) {
			moduleDropdown = Array.isArray(moduleList) ? moduleList.map((obj, index) => {
				return <option value={obj.moduleId} key={`module${index}`} >{obj.moduleName}</option>
			}) : []

		} else {
			moduleDropdown = <option value="" >No Module found</option>
		}

		const selectedModules = cloneDeep(this.state.selectedModules);
		const modulesTable = Array.isArray(selectedModules) ? selectedModules.map((item, index) => {
			const module = moduleList.find(module => module.moduleId.toString() === item);
			return <tr key={index}>
				<td>{module.moduleName}</td>
				<td>
					<i onClick={() => this.handleRemoveModuleRow(module.moduleId.toString())} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		}) : [];

		const parentModuleList = cloneDeep(this.state.parentModuleList);
		let parentModuleDropdown = '';
		if (!isEmpty(parentModuleList) && (!('error' in parentModuleList))) {
			parentModuleDropdown = Array.isArray(parentModuleList) ? parentModuleList.map((obj, index) => {
				return <option value={obj.moduleId} key={`module${index}`} >{obj.moduleName}</option>
			}) : []

		} else {
			parentModuleDropdown = <option value="" >No Module found</option>
		}

		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
			};
			const sendLater = this.state.sendLater;
			let sendLaterDatepicker = '';
			sendLaterDatepicker = 
			<Col sm={6}>
				<Form.Group className="form-group expiry required control-label">
					<Form.Label>User Expiry</Form.Label>
					<DatePicker
						className="form-control"
						selected={this.state.sendDate}
						minDate={new Date()}
						timeCaption="Time"
						dateFormat="yyyy-MM-dd"
						onChange={this.sendLaterDateChange}
						filterTime={filterTime}
					/>
				</Form.Group>
			</Col>
			
		return (
			<Form method="post" onSubmit={this.registerUser}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Create New User</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="username" type="text" placeholder="Username" onChange={e => this.setvalue(e)} value={this.state.username} autoComplete="off" />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="password" type="password" placeholder="Password" onChange={e => this.setvalue(e)} value={this.state.password} autoComplete="new-password"/>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="confirmPassword" type="password" placeholder="Confirm Password" onChange={e => this.setvalue(e)} value={this.state.confirmPassword} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="name" type="text" placeholder="Name" onChange={e => this.setvalue(e)} value={this.state.name} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="emailid" type="email" placeholder="Email ID" onChange={e => this.setvalue(e)} value={this.state.emailid} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="number" type="number" placeholder="Mobile Number" onChange={e => this.setvalue(e)} value={this.state.number} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="company" type="text" placeholder="Company Name" onChange={e => this.setvalue(e)} value={this.state.company} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="address" type="text" placeholder="Address" onChange={e => this.setvalue(e)} value={this.state.address} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="pincode" type="number" placeholder="Pincode" onChange={e => this.setvalue(e)} value={this.state.pincode} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="userType" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Customer Type</option>
												<option value="reseller">Reseller</option>
												<option value="user">User</option>
											</Form.Select>
										</Form.Group>
									</Col>
									{(this.props.app.role === 'ROLE_SADMIN') && <Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="accountType" value={this.state.accountType} onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Account Type</option>
												<option value="0">Promotional</option>
												<option value="1">Transactional</option>
											</Form.Select>
										</Form.Group>
									</Col>}
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="planId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Voice Plan</option>
												{planDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									{(this.props.app.role === 'ROLE_ADMIN') && <Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="accountType" value={this.state.accountType} onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Account Type</option>
												{(this.state.parentAccount === 0) && <option value="0">Promotional</option>}
												{(this.state.parentAccount === 1) && <option value="1">Transactional</option>}
											</Form.Select>
										</Form.Group>
									</Col>}
								</Row>
								<Row>
									{sendLaterDatepicker}
								</Row>
								<Row>
									{(this.props.app.role === 'ROLE_SADMIN') && <>
										<Col sm={5}>
											<Form.Group className="form-group channels control-label">
												<Form.Select required name="selectedModuleId" onChange={e => this.setvalue(e)} as="select">
													<option value="">Select Module</option>
												{moduleDropdown}
											</Form.Select>
											</Form.Group>
										</Col>
											<Col sm={1}>
												<i onClick={this.handleModuleSelect} className="fa fa-plus-circle plus-icon"></i>
											</Col>
									</>}
									{(this.props.app.role === 'ROLE_ADMIN') && <>
										<Col sm={5}>
											<Form.Group className="form-group channels control-label">
												<Form.Select required name="selectedModuleId" onChange={e => this.setvalue(e)} as="select">
														<option value="">Select Module</option>
													{parentModuleDropdown}
												</Form.Select>
											</Form.Group>
										</Col>
										<Col sm={1}>
												<i onClick={this.handleModuleSelect} className="fa fa-plus-circle plus-icon"></i>
										</Col>
									</>}
									<Col sm={6}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Module</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{modulesTable}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>
                                
								{(this.state.ttsCreditsFlag) && <Row>
                                    <Col sm={6}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="ttsCredits" type="number" placeholder="Add TTS Credits" onChange={e => this.setvalue(e)} value={this.state.ttsCredits} />
										</Form.Group>
									</Col>

								</Row>}

								{(this.props.app.role === 'ROLE_SADMIN') && <Row>
									<Col sm={5}>
										<Form.Group className="form-group"  >
											<Form.Select required name="groupId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Group</option>
												{groupDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={1}>
										<i onClick={this.handleAddGroupRow} className="fa fa-plus-circle plus-icon"></i>
									</Col>
									<Col sm={5}>
										<Form.Group className="form-group"  >
											<Form.Select required name="locationId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Location</option>
												{locationDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={1}>
										<i onClick={this.handleAddLocationRow} className="fa fa-plus-circle plus-icon"></i>
									</Col>
								</Row>}
								{(this.props.app.role === 'ROLE_SADMIN') && <Row>
									<Col sm={6}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Group</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{groupsTable}
												</tbody>
											</table>
										</div>
									</Col>
									
									<Col sm={6}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Location</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{locationsTable}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>}
                               
							   <Row>
									{/* {(this.props.app.role === 'ROLE_SADMIN') && <Col sm={3}>
										<Form.Group className="form-group" >
											<Form.Check checked={this.state.cutting} onChange={( e ) => this.toggleChange( e, 'cutting' )} custom inline name="cutting" label="Cutting" id="cutting" type="checkbox" />
										</Form.Group>
									</Col>} */}
									{(this.props.app.role === 'ROLE_SADMIN' || (this.state.showSubmission)) && <Col sm={3}>
										<Form.Group className="form-group" >
											<Form.Check checked={this.state.isSub} onChange={( e ) => this.toggleChange( e, 'isSub' )} custom inline name="isSub" label="Submission" id="isSub" type="checkbox" />
										</Form.Group>
										</Col>}
								</Row>

								<Row>
								  {/* {this.renderCuttingOption('Cutting1')} */}
								  {((matchingPlan && matchingPlan.pulseDuration == 1) || matchingPlan === undefined ) && this.renderSettingOption('Setting1')}
								  {this.renderSettingOption('Setting2')}
								  {this.renderSettingOption('Setting3')}
								  {this.renderSettingOption('Setting4')}
								</Row>

								<Row>
									<Col sm={6}>
										<Form.Group className="form-group" >
											<Form.Check checked={this.state.logoDomain} onChange={( e ) => this.toggleChange( e, 'logoDomain' )} custom inline name="logoDomain" label="Send Logo and Domain" id="logoDomian" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>
								{(this.state.logoDomain) && <Row>
									<Col sm={6}>
										<Form.Group className="form-group"  >
											<Form.Label>Logo</Form.Label>
											<Form.Control name="logo" onChange={this.onFileChangeHandler} ref={this.logoInput} type="file" />
										</Form.Group>
									</Col>
									<Col sm={6}>
										<Form.Group className="form-group" >
										<Form.Label>Domain</Form.Label>
											<Form.Control name="domain" type="text" placeholder="Domain" onChange={e => this.setvalue( e )} value={this.state.domain} />
										</Form.Group>
									</Col>
								</Row>}
								<Button className="btn-round" variant="primary" type="submit" disabled={this.state.loading}>Create User</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default CreateUser;