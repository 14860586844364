import React, { Component } from 'react';
import { Card, Button, Col, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import ReactTooltip from 'react-tooltip';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from 'js-cookie';

import "./index.css";
class CreditsHistory extends Component {
	constructor(props) {

		super(props);
		this.state = {
			creditsList: [],
			startDate: '',
            endDate: '',
			sizePerPage: 10
		}
	}
	componentDidMount() {
		this.getCreditsHistory();
		const savedSizePerPage = Cookies.get('creditListsizePerPage');
		if (savedSizePerPage) {
			this.setState({ sizePerPage: Number(savedSizePerPage) });
		}
	}


	generateReport = (campaignId, reportType = 'campaign_deduction') => {
		const data = {
			"campaignId": campaignId,
			"reportType": reportType
		};
		return Fetcher.post(this.props.app.apiPath + '/api/obd/report/generate', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				alert(res.message);
				this.getCampaigns();
			})
			.catch(err => { console.log('Error in Generating Report', err) });
	}




	getCreditsHistory = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		const data = {
            userId:userId,
            startDate : this.state.startDate,
            endDate : this.state.endDate,
        }
		if(this.props.app.role === 'ROLE_USER')
		{
			Fetcher.post(apiPath + '/api/obd/credits/history/user',{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				this.setState({
					creditsList: res
				})
			})
			.catch(err => { console.log('Error in fetching Credits History List', err) });
		}
		else
		{
			Fetcher.post(apiPath + '/api/obd/credits/history',{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				this.setState({
					creditsList: res
				})
			})
			.catch(err => { console.log('Error in fetching Credits History List', err) });
		}
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
			showModal: false,
			showAddCreditModal: false,
		});
	}

	handleStartDateChange = (date) => {
		this.setState({
			startDate: date,
	    });
    };

    handleEndDateChange = (date) => {
    		this.setState({
    			endDate: date,
    	 });
    };

	formatDate = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
	
		return `${year}-${month}-${day}`;
	};

	searchCdr = async (e) => {
        e.preventDefault();
        let sDate = "";
        let eDate ="";
        if(this.state.startDate === ""){
                 sDate = this.state.startDate
        }else{
            sDate =  this.formatDate(this.state.startDate)
        }
        if(this.state.endDate === ""){
                 eDate = this.state.endDate
        }else{
            eDate =  this.formatDate(this.state.endDate)
        }
        const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		const data = {
            userId:userId,
            startDate : sDate,
            endDate : eDate,
        }
		if(this.props.app.role === 'ROLE_USER')
		{
			Fetcher.post(apiPath + '/api/obd/credits/history/user',{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				this.setState({
					creditsList: res
				})
			})
			.catch(err => { console.log('Error in fetching Credits History List', err) });
		}
		else
		{
			Fetcher.post(apiPath + '/api/obd/credits/history',{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				this.setState({
					creditsList: res
				})
			})
			.catch(err => { console.log('Error in fetching Credits History List', err) });
		}
    }

	handleSizePerPageChange = (newSizePerPage) => {
		this.setState({ sizePerPage: newSizePerPage });
		Cookies.set('creditListsizePerPage', newSizePerPage);
	}

	render() {

		const creditsList = Array.isArray(this.state.creditsList) ? this.state.creditsList: [];
		var columns;
		if (this.props.app.role === 'ROLE_SADMIN') {

			columns = [
				{
					dataField: 'campId',
					text: 'Campaign Id',
					sort: true,
					hidden: false
				},
				{
					dataField: 'chId',
					text: 'Credit History Id',
					sort: true,
				},
				{
					dataField: 'userId',
					text: 'User Id',
					sort: true,
				},
				{
					dataField: 'fromUsername',
					text: 'From',
					sort: false,
					hidden: false
				},{
					dataField: 'toUsername',
					text: 'To',
					sort: false,
					hidden: false
				},{
					dataField: 'credits',
					text: 'Amount',
					sort: true,
					searchable: true
				}, {
					dataField: 'campaignName',
					text: 'Campaign',
					sort: true,
					searchable: true
				}, {
					dataField: 'action',
					text: 'Action',
					sort: true,
					searchable: false
				}, {
					dataField: 'userBalance',
					text: 'Balance',
					sort: true,
					searchable: false
				},{
					dataField: 'reqDate',
					text: 'Date',
					sort: true,
					searchable: false
				},
				{
					dataField: 'bd',
					text: 'BD',
					sort: true,
					searchable: false
				},
				{
					dataField: 'abd',
					text: 'ABD',
					sort: true,
					searchable: false
				},
				{
					dataField: 'downLoadReport',
					text: 'Action',
					sort: false,
					searchable: false,
					headerStyle: { width: '130px' }
				}
			];
		}
		else if (this.props.app.role === 'ROLE_ADMIN') {
			columns = [
				{
					dataField: 'chId',
					text: 'Credit History Id',
					sort: true,
				},
				{
					dataField: 'userId',
					text: 'User Id',
					sort: true,
				},
				{
					dataField: 'toUsername',
					text: 'From/To',
					sort: false,
					hidden: false
				},{
					dataField: 'credits',
					text: 'Amount',
					sort: true,
					searchable: true
				}, {
					dataField: 'campaignName',
					text: 'Campaign',
					sort: true,
					searchable: true
				}, {
					dataField: 'action',
					text: 'Action',
					sort: true,
					searchable: false
				}, {
					dataField: 'userBalance',
					text: 'Balance',
					sort: true,
					searchable: false
				},{
					dataField: 'reqDate',
					text: 'Date',
					sort: true,
					searchable: false
				},];
		}
		else {
			columns = [
				{
					dataField: 'chId',
					text: 'Credit History Id',
					sort: true,
				},
				{
					dataField: 'credits',
					text: 'Amount',
					sort: true,
					searchable: true
				}, {
					dataField: 'campaignName',
					text: 'Campaign',
					sort: true,
					searchable: true
				}, {
					dataField: 'action',
					text: 'Action',
					sort: true,
					searchable: false
				}, {
					dataField: 'reqDate',
					text: 'Date',
					sort: true,
					searchable: false
				},];
		}
		const users = creditsList.map((user, index) => {
			if (this.props.app.role === 'ROLE_SADMIN') {

				const downLoadReport = (user.action === 'campaign_deduction') ? <a data-tooltip-content="Download Report" key={'sdsd'} className="download-report" onClick={(e) => { this.generateReport(user.campId) }} href="#"><i className="fa fa-download"></i></a> : <></>;

				return {
					campId: user.campId,
					chId: user.chId,
					userId: user.userId,
					fromUsername: (user.action === 'campaign_deduction')?'Campaign':user.fromUsername,
					toUsername: user.toUsername,
					company: user.company,
					parentUser: user.parentUser,
					credits: user.credits,
					campaignName: user.campaignName,
					action: user.action,
					userBalance: user.userBalance,
					reqDate: user.reqDate,
					bd : user.bd,
                    abd : user.abd,
					downLoadReport
				};
			}
			else if (this.props.app.role === 'ROLE_ADMIN') {
				return {
					chId: user.chId,
					userId: user.userId,
					toUsername: user.toUsername,
					company: user.company,
					parentUser: user.parentUser,
					credits: user.credits,
					campaignName: user.campaignName,
					action: user.action,
					userBalance: user.userBalance,
					reqDate: user.reqDate
				};
			}
			else {
				return {
					chId: user.chId,
					credits: user.credits,
					campaignName: user.campaignName,
					action: user.action,
					reqDate: user.reqDate
				};
			}
		})
		const { SearchBar, ClearSearchButton } = Search;
		const { ExportCSVButton } = CSVExport;

		const defaultSorted = [{
			dataField: 'reqDate',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: users.length,
			sizePerPage: this.state.sizePerPage,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: this.handleSizePerPageChange
		};

		return (
			<Card>
				<Card.Header>Credits History</Card.Header>
				<Card.Body>
					<div className="widget-list-table-cont">
					<Form className="add-campaign-form" method='post' onSubmit={(e) => this.searchCdr(e)}> 
					        <Row>
						        <Col sm={2}>
                                    <Form.Group className="form-group col-md-12 float-left" style={{ paddingLeft: 0 }}>
					                    <DatePicker
						                    className="form-control"
						                    dateFormat="yyyy-MM-dd"
                                            selected={this.state.startDate}
                                            onChange={this.handleStartDateChange}
                                            placeholderText="Start Date"
					                    />
				                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group className="form-group col-md-12 float-left" style={{ paddingLeft: 0 }}>
					                    <DatePicker
						                    className="form-control"
						                    dateFormat="yyyy-MM-dd"
						                    selected={this.state.endDate}
                                            onChange={this.handleEndDateChange}
                                            placeholderText="End Date"
					                    />
				                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Button variant="primary" type="submit">Search</Button>
                                </Col>
						    </Row>	
					    </Form> 
						{/* <h3>Campaign Summary</h3> */}
						{/*{!isEmpty( this.state.creditsList ) && <PaginationProvider pagination={paginationFactory( paginationOption )}>*/}
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="reqDate"
										data={users}
										columns={columns}
										search
										bootstrap4
										exportCSV={ {
											fileName: 'credits-history.csv'
										  } }

									>
										{
											props => (
												<div className="">
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													<ExportCSVButton className="export-csv btn-primary float-right" {...props.csvProps}>Export to CSV</ExportCSVButton>
													{/*<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span>*/}
													
													<hr/>
													<BootstrapTable defaultSorted={defaultSorted} hover bordered={true} condensed wrapperClasses="table-responsive" classes="widgetlist-table"  {...props.baseProps}  {...paginationTableProps} noDataIndication={() => (<div>{'No data available'}</div>)} />
													<PaginationListStandalone {...paginationProps} />
													<ReactTooltip />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>
				</Card.Body>
			</Card>
		);
	}
}

export default CreditsHistory;