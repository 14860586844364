import React, { Component } from 'react';
import { Card, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport} from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import Dropdown from 'react-bootstrap/Dropdown';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from 'js-cookie';
import { cloneDeep, isEmpty } from 'lodash';

import "./index.css";
import { Link } from 'react-router-dom';
class HistoricalInboundCampaign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			campaignlist: [],
			selectedCampaignId: 0,
			isButtonDisabled: [],
			confirmStop: false,
			confirmPause: false,
			confirmResume: false,
			confirmRetry: false,
			reloading: false,
			allSelected: false,
			retryOptionStates: {},
			apiRetryOptions: [],
			startDate: '',
            endDate: '',
			loading: false,
			sizePerPage: 10,
			searchCampaignName : '',
			searchDid : '',
			allocatedDedicatedCliList : []
		}
		//<Route path={`${urlPrefix}/campaign-summary`} ><ListCampaign app={props.app} /></Route>
	}
	componentDidMount() {
		this.getCampaigns();
		const savedSizePerPage = Cookies.get('histoInboundCampSum_S_P_P');
		if (savedSizePerPage) {
			this.setState({ sizePerPage: Number(savedSizePerPage) });
		}

		const userId = this.props.app.userId;
		Fetcher.get(this.props.app.apiPath + '/api/obd/dedicated/cli/allocated/' + userId)
			.then(res => {
				this.setState({
					allocatedDedicatedCliList: res
                	
				})
				
			})
			.catch(err => {
				console.log('Error in fetching dedicated cli', err);
		});
	}

	getCampaigns = () => {
		const userId = this.props.app.userId;
		const data = {
            userId:userId,
            startDate : this.state.startDate,
            endDate : this.state.endDate,
			campaignName : "",
			did : ""
        }
		// this.props.app.apiPath
		return Fetcher.post(this.props.app.apiPath  + '/api/obd/inbound/campaign/historical', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				this.setState({
					campaignlist: res,
					confirmStop: false,
					confirmPause: false,
					confirmResume: false,
					confirmRetry: false
				})
			})
			.catch(err => { console.log('Error in fetching Campaigns', err) });
	}

	refreshPage = () => {
		this.setState({ reloading: true });
		//window.location.reload();

		setTimeout(() => {
			window.location.reload(false);
		}, 500);
	}

	downloadReport = (index, campaignId, type) => {
		const data = {
			"campaignId": campaignId,
			"reprtType": type
		};
		return Fetcher.post(this.props.app.apiPath + '/api/obd/report/download', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				alert(res.message);
				//document.getElementById('report-' + index).disabled = false;
				//document.getElementById('report-' + index).innerHTML = "Download";
				window.open(this.props.app.reportPath + '/reports/' + campaignId + '.zip', 'Download');
			})
			.catch(err => { console.log('Error in Downloading Report', err) });
	}

	generateReport = (index, campaignId, reportType) => {
		const data = {
			"campaignId": campaignId,
			"reportType": reportType
		};
		return Fetcher.post(this.props.app.apiPath + '/api/obd/report/generate', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {

				alert(res.message);
				this.getCampaigns();
				
				//document.getElementById("greport-" + index).disabled = true;
				//document.getElementById('greport-' + index).innerHTML = "Preparing";
				
			})
			.catch(err => { console.log('Error in Generating Report', err) });
	}

	downloadPending = (index, campaignId) => {
		const data = {
			"campaignId": campaignId,
			"reportType": 'pending_data'
		};
		return Fetcher.post(this.props.app.apiPath + '/api/obd/report/pending', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				//let csvRows = res.join('\n');
				let csvRows = res.map(item => item.substring(0, 10)).join('\n');
				// Create a Blob and initiate the download
				const blob = new Blob([csvRows], { type: 'text/csv' });
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = 'pending_data_'+campaignId+'.csv';
				link.click();
			})
		.catch(err => { 
			Notify.notify('Something went wrong');
			console.log('Error in Downloading Report', err) });
	}

	confirmStop = (status, campaignId) => {
		this.setState({
			confirmStop: status,
			selectedCampaignId: campaignId
		});
	}

	confirmPause = (status, campaignId) => {
		this.setState({
			confirmPause: status,
			selectedCampaignId: campaignId
		});
	}

	confirmResume = (status, campaignId) => {
		this.setState({
			confirmResume: status,
			selectedCampaignId: campaignId
		});
	}
    

	setvalue = (e) => {
		const { name, value } = e.target;
		
		    this.setState({
			    [e.target.name]: e.target.value,
		    });
		
	}

     searchCampaign = async (e) => {
        e.preventDefault();
        
		const userId = this.props.app.userId;
		const data = {
            userId:userId,
            startDate : this.state.startDate,
            endDate : this.state.endDate,
			campaignName : this.state.searchCampaignName,
			did : ""
        }
		// this.props.app.apiPath
		return Fetcher.post('http://localhost:8080' + '/api/obd/inbound/campaign/historical', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				this.setState({
					campaignlist: res,
					confirmStop: false,
					confirmPause: false,
					confirmResume: false,
					confirmRetry: false
				})
			})
			.catch(err => { console.log('Error in fetching Campaigns', err) });
    }




	confirmRetry = (status, campaignId) => {
		if(this.state.loading) {
			return;
		}
		this.setState({
			confirmRetry: status,
			selectedCampaignId: campaignId
		});
		if (status) {
			Fetcher.get(this.props.app.apiPath + '/api/obd/campaign/retry/options/' + campaignId)
			.then(res => {
				const retryOptionStates = {};
                res.forEach(option => {
                    retryOptionStates[option.optionName] = false;
                });
                this.setState({
                    apiRetryOptions: res,
					retryOptionStates: retryOptionStates 
                });
            })
            .catch(err => {
                console.log('Error in fetching retry options', err);
            });
		}
	}

	stopCampaign = () => {
		const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/stop', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
			})
			.catch(err => {
				console.log('Error in Stopping', err);
			});
	}

	pauseCampaign = () => {
		const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/pause', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
			})
			.catch(err => {
				console.log('Error in Pause', err);
			});
	}


	getCampaignAdditionalInfo = (campaignId, type) => {
		if(this.state.loading === true){
			return;
		}
		this.setState({
			...(type === 'location' && { activeLocationcampaignId: campaignId }),
			...(type === 'cli' && { activeClicampaignId: campaignId }),
			loading: true
		})
		const apiPath = this.props.app.apiPath;
		Fetcher.get(apiPath + '/api/obd/inbound/campaignInfo/' + campaignId)
			.then(res => {
				this.setState({
					selectedCampaignLocationList: res.locationList,
					selectedCampaignCliList: res.dids,
					loading: false
				})
			})
			.catch(err => { 
				console.log('Error in fetching Location list', err) 
				this.setState({
					loading: false
				})
			});
	}
	
	resumeCampaign = () => {
		const campaignId = this.state.selectedCampaignId;
		const data = {
			campaignId: campaignId,
			userId: this.props.app.userId
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/resume', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
			})
			.catch(err => {
				console.log('Error in Resume', err);
			});
	}

	retryCampaign = () => {
		this.setState({ loading: true });
		const userId = this.props.app.userId;
		const campaignId = this.state.selectedCampaignId;
		const { retryOptionStates, allSelected } = this.state;
		let selectedOptions;
		if (allSelected) {
			selectedOptions = ["All"];
		} else {
			selectedOptions = Object.keys(retryOptionStates).filter((option) => retryOptionStates[option]);
		}

		if (selectedOptions.length === 0) {
			alert("Please select at least one option.");
			this.setState({ loading: false });
			return;
		}
		const selectedOptionsString = selectedOptions.join(':');
		const data = {
			campaignId: campaignId,
			userId: userId,
			selectedOptions: selectedOptionsString
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/campaign/retry', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCampaigns();
				// Reset all option states to false
				const updatedOptionStates = {};
				Object.keys(retryOptionStates).forEach(option => {
					updatedOptionStates[option] = false;
				});
				this.setState({ 
					retryOptionStates: updatedOptionStates,
					allSelected: false,
					loading: false
				});
			})
			.catch(err => {
				console.log('Error in Retry', err);
				this.setState({ loading: false  });
			});
	}

	handleStartDateChange = (date) => {
		this.setState({
			startDate: date,
	    });
    };

    handleEndDateChange = (date) => {
    		this.setState({
    			endDate: date,
    	 });
    };

	formatDate = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
	
		return `${year}-${month}-${day}`;
	};

	searchCdr = async (e) => {
        e.preventDefault();
        let sDate = "";
        let eDate ="";
        if(this.state.startDate === ""){
                 sDate = this.state.startDate
        }else{
            sDate =  this.formatDate(this.state.startDate)
        }
        if(this.state.endDate === ""){
                 eDate = this.state.endDate
        }else{
            eDate =  this.formatDate(this.state.endDate)
        }
        const userId = this.props.app.userId;
		const data = {
            userId:userId,
            startDate : sDate,
            endDate : eDate,
			campaignName : this.state.searchCampaignName,
			did : this.state.searchDid
        }
		// this.props.app.apiPath 
		Fetcher.post(this.props.app.apiPath  + '/api/obd/inbound/campaign/historical' ,{ headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				this.setState({
					campaignlist: res,
					confirmStop: false,
					confirmPause: false,
					confirmResume: false,
					confirmRetry: false
				})
			})
			.catch(err => { console.log('Error in fetching Campaigns', err) });
    }

	toggleCheckedChange = (e, optionName) => {
        const { checked } = e.target;
        if (optionName === 'All') {
            const updatedOptionStates = {};
            Object.keys(this.state.retryOptionStates).forEach((key) => {
                updatedOptionStates[key] = checked;
            });
            this.setState({
                allSelected: checked,
                retryOptionStates: updatedOptionStates,
            });
        } else {
            this.setState((prevState) => ({
                retryOptionStates: {
                    ...prevState.retryOptionStates,
                    [optionName]: checked,
                },
            }));
        }
    };

	handleSizePerPageChange = (newSizePerPage) => {
		this.setState({ sizePerPage: newSizePerPage });
		Cookies.set('histoVoicCampSum_S_P_P', newSizePerPage);
	}

	render() {

		const campaigns = this.state.campaignlist;
		let columns = [];
		if(this.props.app.role === 'ROLE_USER'){
		 columns = [
			{
				dataField: 'id',
				text: 'Serial No.',
				sort: false,
				hidden: true,
				csvExport: false
			},
			{
				dataField: 'campaignId',
				text: 'Campaign ID',
				sort: false,
				hidden: false,
				csvExport: true
			},
			{
				dataField: 'campaignName',
				text: 'Campaign Name',
				sort: true,
				searchable: true,
				csvExport: false
			}, 
            {
				dataField: 'campaignstatus',
				text: 'Campaign Status',
				sort: true,
				searchable: true
			}, {
				dataField: 'templateId',
				text: 'Campaign Type',
				sort: true,
				searchable: true
			},
			{
				dataField: 'cliList',
				text: 'CLI',
				sort: true,
				searchable: false
			},
            {
				dataField: 'scheduleTime',
				text: 'Start Time',
				sort: true,
				searchable: false
			},{
				dataField: 'endTime',
				text: 'End Time',
				sort: true,
				searchable: false
			},
			{
				dataField: 'numbersProcessed',
				text: 'Calls Dialed',
				sort: false,
				searchable: false
			},
             {
				dataField: 'callsConnected',
				text: 'Connected Calls',
				sort: false,
				searchable: false,
				csvExport: false
			},{
				dataField: 'pulses',
				text: 'Total Pulses',
				sort: true,
				searchable: false
			},{
				dataField: 'dtmfCount',
				text: 'Dtmf Count',
				sort: true,
				searchable: false,
				csvExport: false
			},{
				dataField: 'smsCount',
				text: 'SMS Count',
				sort: true,
				searchable: false
			},{
				dataField: 'fullReportStatus',
				text: 'Action',
				sort: false,
				searchable: false,
				csvExport: false
			}];
		}else{
			
			columns = [
			{
				dataField: 'id',
				text: 'Serial No.',
				sort: false,
				hidden: true,
				csvExport: false
			},
			{
				dataField: 'campaignId',
				text: 'Campaign ID',
				sort: false,
				hidden: false,
				csvExport: true
			},
			{
				dataField: 'campaignName',
				text: 'Campaign Name',
				sort: true,
				searchable: true,
				csvExport: false
			}, 
			{
				dataField: 'username',
				text: 'Username',
				sort: true,
				searchable: true,
				csvExport: false
			}, 
            {
				dataField: 'campaignstatus',
				text: 'Campaign Status',
				sort: true,
				searchable: true
			}, {
				dataField: 'templateId',
				text: 'Campaign Type',
				sort: true,
				searchable: true
			},
			{
				dataField: 'cliList',
				text: 'CLI',
				sort: true,
				searchable: false
			},
            {
				dataField: 'scheduleTime',
				text: 'Start Time',
				sort: true,
				searchable: false
			},{
				dataField: 'endTime',
				text: 'End Time',
				sort: true,
				searchable: false
			},
			{
				dataField: 'numbersProcessed',
				text: 'Calls Dialed',
				sort: false,
				searchable: false
			},
             {
				dataField: 'callsConnected',
				text: 'Connected Calls',
				sort: false,
				searchable: false,
				csvExport: false
			},{
				dataField: 'pulses',
				text: 'Total Pulses',
				sort: true,
				searchable: false
			},{
				dataField: 'dtmfCount',
				text: 'Dtmf Count',
				sort: true,
				searchable: false,
				csvExport: false
			},{
				dataField: 'smsCount',
				text: 'SMS Count',
				sort: true,
				searchable: false
			},{
				dataField: 'fullReportStatus',
				text: 'Action',
				sort: false,
				searchable: false,
				csvExport: false
			}];
		}

		const products = Array.isArray(campaigns) ? campaigns.map((campaign, index) => {
			let template = '';

			switch (campaign.templateId) {
				case 0:
					template = 'Simple IVR';
					break;
				case 1:
					template = 'DTMF';
					break;
				case 2:
					template = 'Call Patch';
					break;
				case 3:
					template = 'Custom IVR';
					break;
				case 6:
					template = 'Inbound Call Patch';
					break;
				default:
					template = '';
					break;
			}
			let status = '';

			switch (campaign.campaignStatus) {
				case 0:
					status = 'Ready';
					break;
				case 21:
					status = 'Processing';
					break;
				case 1:
					status = 'Running';
					break;
				case 2:
					status = 'Complete';
					break;
				case 3:
					status = 'Low Balance';
					break;
				case 4:
					status = 'Error';
					break;
				case 5:
					status = 'Error';
					break;
				case 6:
					status = 'Cancelling';
					break;
				case 7:
					status = 'Cancelled';
					break;
				case 8:
					status = 'Pausing';
					break;
				case 9:
					status = 'Paused';
					break;
				case 10:
					status = 'Resuming';
					break;
				case 22:
					status = 'Waiting For Channels';
						break;
				default:
					status = '';
					break;
			}
			
			let action = [];
			let retry = [];
			//let 
			action.push(<Dropdown id="dd">
				<Dropdown.Toggle className='btn-sm'>Take Action</Dropdown.Toggle>
					<Dropdown.Menu>
						{(this.props.app.role === 'ROLE_USER') ? (<Dropdown.Item key={`clone-inbound-campaign-${index}`} href={`${this.props.app.urlPrefix}/clone-inbound-campaign/${campaign.campaignId}`}>Clone Campaign</Dropdown.Item>) : <></>}
						{(campaign.campaignStatus === 1) && <Dropdown.Item key={`ps-campaign-${index}`} onClick={() => this.confirmPause(true, campaign.campaignId)} href="#">Pause Campaign</Dropdown.Item>}
						{(campaign.campaignStatus === 9 || campaign.campaignStatus === 22) && <Dropdown.Item key={`rs-campaign-${index}`} onClick={() => this.confirmResume(true, campaign.campaignId)} href="#">Resume Campaign</Dropdown.Item>}
						{(campaign.campaignStatus === 1) && <Dropdown.Item key={`st-campaign-${index}`} onClick={() => this.confirmStop(true, campaign.campaignId)} href="#">Stop Campaign</Dropdown.Item>}
						{(campaign.campaignStatus === 2) && <Dropdown.Item key={`st-campaign-${index}`} onClick={() => this.confirmStop(true, campaign.campaignId)} href="#">Start Campaign</Dropdown.Item>}
						{ (this.props.app.role === 'ROLE_USER') ? ((campaign.fullReportStatus === '2' || campaign.fullRreportStatus === undefined) && <Dropdown.Item id={`greport-${index}`} key={`greport-${index}`} onClick={() => this.generateReport(index, campaign.campaignId, 'inbound_full')} href="#">Generate Full Report</Dropdown.Item>) : <></>}
						
					</Dropdown.Menu>
				  </Dropdown>);
				if(campaign.campaignStatus === 2 && campaign.isRetry === 0)
				{
					retry.push(<Link data-tip="Retry" key={`${index}-retryy`} className="btn btn-sm btn-primary" disabled={this.state.loading} onClick={(e) => { this.confirmRetry(true, campaign.campaignId) }}>Retry</Link>);
				}
				let callsSubmitted=0;
				let dtmfDownload=[];
				dtmfDownload.push(campaign.dtmfCount);
				dtmfDownload.push(' ');
				if(campaign.templateId === 1 || campaign.templateId === 2)
				{
					if(campaign.dtmfReportStatus === '2' || campaign.dtmfReportStatus === undefined)
					{
						dtmfDownload.push( <a data-tip="" key={`${index}-dtmf`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'dtmf' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}
				let dtmf1Download=[];
				dtmf1Download.push(campaign.dtmf1Count);
				dtmf1Download.push(' ');
				if(campaign.templateId === 3)
				{
					if(campaign.dtmfReportStatus === '2' || campaign.dtmfReportStatus === undefined)
					{
						dtmf1Download.push( <a data-tip="" key={`${index}-dtmf1`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'dtmf1' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}
				let dtmf2Download=[];
				dtmf2Download.push(campaign.dtmf2Count);
				dtmf2Download.push(' ');
				if(campaign.templateId === 3)
				{
					if(campaign.dtmfReportStatus === '2' || campaign.dtmfReportStatus === undefined)
					{
						dtmf2Download.push( <a data-tip="" key={`${index}-dtmf2`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'dtmf2' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}
				let ccDownload=[];
				ccDownload.push(campaign.callsConnected);
				ccDownload.push(' ');
				if(campaign.callsConnected > 0)
				{
					if(campaign.ccReportStatus === '2' || campaign.ccReportStatus === undefined)
					{
						ccDownload.push( <a data-tip="" key={`${index}-cc`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'connected_calls' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}
				let ccPending=[];
				ccPending.push(campaign.numbersUploaded - campaign.dndCount - campaign.numbersProcessed);
				ccDownload.push(' ');
				ccPending.push( <a data-tip="" key={`${index}-pending`} className="download-report" onClick={( e ) => { this.downloadPending( true, campaign.campaignId ) }} href="#"><i className="fa fa-download"></i></a>);
	
				let smsDownload = [];
				smsDownload.push(campaign.smsCount);
				smsDownload.push(' ');
				if(campaign.smsCount > 0){
					if(campaign.smsStatus == 2 || campaign.smsStatus == undefined){
						smsDownload.push( <a data-tip="" key={`${index}-sms`} className="download-report" onClick={( e ) => { this.generateReport( true, campaign.campaignId, 'sms' ) }} href="#"><i className="fa fa-download"></i></a>);
					}
				}

				let selectedCliAction;

				if(this.state.activeClicampaignId !== campaign.campaignId){
					selectedCliAction = (
						<Dropdown show={false}>
							<Dropdown.Toggle onClick={() => this.getCampaignAdditionalInfo(campaign.campaignId, 'cli')} size="sm" variant="success">CLI</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item>
									CLI
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					);
				} else {
					selectedCliAction = (
						<Dropdown show={this.state.activeClicampaignId === campaign.campaignId} onToggle={(isOpen) => this.handleDropdownToggle(isOpen, 'cli')}>
							<Dropdown.Toggle className="btn-sm" variant="success">CLI</Dropdown.Toggle>
							<Dropdown.Menu>
								{isEmpty(this.state.selectedCampaignCliList) ? (
									<Dropdown.Item disabled>No CLI found</Dropdown.Item>
								) : (
									this.state.selectedCampaignCliList.map((cli, index) => (
										<Dropdown.Item key={`Cli${index}`} disabled>
											{cli.did}
										</Dropdown.Item>
									))
								)}
							</Dropdown.Menu>
						</Dropdown>
					);
				}

			return {
				id: index + 1,
				campaignId: campaign.campaignId,
				name: campaign.campaignName,
				username: campaign.username,
				campaignName: (campaign.templateId === 0 || campaign.templateId === 1 || campaign.templateId === 2)?<Link key={`${index}-cm`} to={`${this.props.app.urlPrefix}/voice-campaign-info/${campaign.campaignId}`}>{campaign.campaignName}</Link>:campaign.campaignName,
				campaignstatus: status,
				templateId: template,
				cliList: selectedCliAction,
				scheduleTime: campaign.scheduleTime,
				endTime: campaign.endTime,
				numbersProcessed: campaign.numbersProcessed,
				callsSubmitted: (campaign.campaignStatus === 7)?ccPending:campaign.numbersUploaded - campaign.dndCount - campaign.numbersProcessed,
				callsConnected: (campaign.campaignStatus === 2)?ccDownload:campaign.callsConnected,
				callsConnectedCsv: campaign.callsConnected,
				pulses: campaign.pulses,
				dtmfCount: (campaign.campaignStatus === 2)?dtmfDownload:campaign.dtmfCount,
				smsCount: (campaign.campaignStatus === 2)? smsDownload : campaign.smsCount,
				retryCount: campaign.retryCount,
				retries: (campaign.retries > 0)?'Yes':'No',
				retries: campaign.retries,
				fullReportStatus: action,
				retry: retry
			};
		}) : []
		const { SearchBar, ClearSearchButton } = Search;
		const { ExportCSVButton } = CSVExport;

		const defaultSorted = [{
			dataField: 'scheduleTime',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: products.length,
			sizePerPage: this.state.sizePerPage,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: this.handleSizePerPageChange
		};
		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
			};

		const cmpList = cloneDeep(this.state.campaignlist);
		let campaignNameDropdown = '';
		if (!isEmpty(cmpList) && (!('error' in cmpList))) {
			    campaignNameDropdown = Array.isArray(cmpList) ? cmpList.map((obj, index) => {
			    return (
				        <option value={obj.campaignName} key={`searchCampaignName${index}`} >{obj.campaignName}</option>
			    );
		}) : [];
		} else {
			    campaignNameDropdown = <p>No Campaign found</p>;
		}


		
		const dedicatedList = cloneDeep(this.state.allocatedDedicatedCliList);
		let dedicatedCliDropdown = '';
		       if (!isEmpty(dedicatedList) && (!('error' in dedicatedList))) {
			       dedicatedCliDropdown = Array.isArray(dedicatedList) ? dedicatedList.map((obj, index) => {
			         return (
				             <option value={obj.cliId} key={`searchDedicatedCli${index}`} >{obj.cli}</option>
			         );
			       }) : [];
		        } else {
			        dedicatedCliDropdown = <p>No DID found</p>;
		        }

		return (
			<Card>
				<Card.Header>Historical Inbound Campaign Summary</Card.Header>
				<Card.Body>
					<div className="campaign-list-table-cont">
					<Form className="add-campaign-form" method='post' onSubmit={(e) => this.searchCdr(e)}> 
					        <Row>
						        <Col sm={2}>
                                    <Form.Group className="form-group col-md-12 float-left" style={{ paddingLeft: 0 }}>
					                    <DatePicker
						                    className="form-control"
						                    dateFormat="yyyy-MM-dd"
                                            selected={this.state.startDate}
                                            onChange={this.handleStartDateChange}
                                            placeholderText="Start Date"
					                    />
				                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group className="form-group col-md-12 float-left" style={{ paddingLeft: 0 }}>
					                    <DatePicker
						                    className="form-control"
						                    dateFormat="yyyy-MM-dd"
						                    selected={this.state.endDate}
                                            onChange={this.handleEndDateChange}
                                            placeholderText="End Date"
					                    />
				                    </Form.Group>
                                </Col>
								 <Col sm={3}>
                                    <Form.Group className="form-group" >
											
										<Form.Select as="select" name="searchCampaignName" onChange={e => this.setvalue(e)}>
												      <option value="">Select Campaign Name</option>
												           {campaignNameDropdown}
										</Form.Select>
									</Form.Group>
                                </Col>

								<Col sm={2}>
                                    <Form.Group className="form-group" >
											
										<Form.Select as="select" name="searchDid" onChange={e => this.setvalue(e)}>
												      <option value="">Select DID</option>
												           {dedicatedCliDropdown}
										</Form.Select>
									</Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Button variant="primary" type="submit">Search</Button>
                                </Col>
						    </Row>	
					    </Form> 
						{/* <h3>Campaign Summary</h3> */}
						{/*{!isEmpty( this.state.campaignlist ) && <PaginationProvider pagination={paginationFactory( paginationOption )}>*/}
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (<ToolkitProvider 
										keyField="id" 
										data={products} 
										columns={columns} 
										search 
										bootstrap4
										exportCSV={ {
											fileName: 'historical_campaign_summary.csv'
										  } }
										>
									{props => (<div className="">
										Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
										<span className="search-cont"><SearchBar {...props.searchProps} /></span>
										<ClearSearchButton className="btn-primary" {...props.searchProps} />
										<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
										<Button id="refresh" type="button" className="float-right btn btn-default btn-primary ml-2" onClick={() => this.refreshPage()}>Refresh</Button>
										<hr/>
										<BootstrapTable bordered={true} hover defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="w-auto campaignlist-table" {...props.baseProps} {...paginationTableProps} noDataIndication={() => (<div>{'No data available'}</div>)} />
										<PaginationListStandalone {...paginationProps} />
									</div>)}
								</ToolkitProvider>)
							}
						</PaginationProvider>}
					</div>
					<Modal size="md" show={this.state.confirmStop} onHide={() => this.confirmStop(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Stop Campaign
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to stop this campaign?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmStop(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.stopCampaign} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>
					
					<Modal size="md" show={this.state.confirmPause} onHide={() => this.confirmPause(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Pause Campaign
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to pause this campaign?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmPause(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.pauseCampaign} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>

					<Modal size="md" show={this.state.confirmResume} onHide={() => this.confirmResume(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Resume Campaign
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to resume this campaign?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmResume(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.resumeCampaign} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>

					<Modal size="md" show={this.state.confirmRetry} onHide={() => this.confirmRetry(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Retry Campaign
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
						    <Row>
							  Are you sure you want to retry this campaign?
							    <Form.Group className="form-group">
								    {Object.keys(this.state.retryOptionStates).length > 0 ? (
							            <>		
								            {Object.keys(this.state.retryOptionStates).length > 1 && (	  
							                    <Form.Check
							                	  checked={this.state.allSelected}
							                	  onChange={(e) => this.toggleCheckedChange(e, 'All')}
							                	  custom
							                	  label="All"
							                	  id="All"
							                	  type="checkbox"
							                    />
								            )}
							                {Object.keys(this.state.retryOptionStates).map((optionName) => (
							                	<Form.Check
							                		key={optionName}
							                		checked={this.state.retryOptionStates[optionName]}
							                		onChange={(e) => this.toggleCheckedChange(e, optionName)}
							                		custom
							                		label={`${optionName} (${this.state.apiRetryOptions.find(option => option.optionName === optionName)?.count || 0})`}
							                		id={optionName}
							                		type="checkbox"
							                	/>
							                ))}
								        </>
							        ) : (
							        	<p>Checking for retry data count</p>
							        )}
						        </Form.Group>
						    </Row>
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmRetry(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.retryCampaign} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>


					{(this.state.reloading === true) && <div className="fetching-data"><img alt="" src="/assets/loading.gif" /></div>}
				</Card.Body>
			</Card>
		);
	}
}


export default HistoricalInboundCampaign;