import React, { Component } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Fetcher } from "../Helpers/fetcher.js";
import './index.css';
class Header extends Component {
	
	constructor(props) {
		super(props);
	this.state = {
		name: '',
		username: '',
		emailid: '',
		number: '',
		company: '',
		planName: '',
		creditsAvailable: '',
		ttsCredits : ''
	};
}
componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		
		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
					name: res.name,
					username: res.username,
					emailid: res.emailid,
					number: res.number,
					company: res.company,
					planName: res.planName,
					creditsAvailable: res.credits,
					ttsCredits : res.ttsCredits
				})
			})
			.catch(err => { console.log('Error in fetching Users data', err) });
		}

		render(){
	return (
		<header className="app-header navbar">
			{this.props.app.userId && <div className="nav-desktop">
				<button onClick={this.props.toggleSidebar} className="navbar-toggler sidebar-toggler d-lg-none " type="button" data-toggle="sidebar-show">
					<span className="navbar-toggler-icon"></span>
				</button></div>}


			{this.props.app.userId && <div className="nav-mobile"> <a className="  navbar-brand" href={this.props.app.siteUrl}>
				{this.props.logo && <img className="navbar-brand-full" src={this.props.logo} alt=" Logo" />}
				{this.props.logo && <img className="navbar-brand-minimized" src={this.props.logo} width="30" height="30" alt=" Logo" />}
			</a><button onClick={this.props.toggleSidebar} className="navbar-toggler sidebar-toggler d-lg-none " type="button" data-toggle="sidebar-show">
					<span className="navbar-toggler-icon"></span>
				</button></div>}
			{this.props.app.userId && <Nav defaultActiveKey="/logout" as="ul">
			    {this.props.app.role !== 'ROLE_SADMIN' && this.props.app.role !== 'ROLE_SUSER' && <Nav.Item as="li">
					<Nav.Link href="#">{this.state.ttsCredits} Characters</Nav.Link>
				</Nav.Item>}
				{this.props.app.role !== 'ROLE_SADMIN' && this.props.app.role !== 'ROLE_SUSER' && <Nav.Item as="li">
					<Nav.Link href="#">Voice Balance(Rs) : {this.state.creditsAvailable}</Nav.Link>
				</Nav.Item>}
				<NavDropdown title={"Welcome " + this.state.username} id="nav-dropdown">
					<Nav.Link className='p-1' href="/user-profile"> Profile</Nav.Link>
					<Nav.Link className='p-1' onClick={this.props.logOutUser} href=""> Logout</Nav.Link>
				</NavDropdown>
			</Nav>}
		</header>
	);
		}

}

export default Header;